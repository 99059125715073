import { LoadingOutlined } from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons/lib/icons";
import { Button, Col, Divider, Form, Input, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { TagsInput } from "react-tag-input-component";
import { useTranslation } from "react-i18next";
import StanButton from "../../../../component/StanButton/StanButton";
import StanTypo from "../../../../component/StanTypo/StanTypo";
import { streamService } from "../../../../services/streamService";
import {
  uploadService,
  uploadVideo,
} from "../../../../services/uploadMediaService";
import "./AddEditStream.css";
import UploadVideo from "./UploadVideo/UploadVideo";
import { useDispatch } from "react-redux";
import { openNotificationWithIcon } from "App";
import * as _ from 'lodash';

const AddEditStreamForm = (props) => {
  const { type, streamInfor, closeModal } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [videoSrc, setVideoSrc] = useState();
  const [videoError, setVideoError] = useState();
  const [titleError, setTitleError] = useState();
  const [video, setVideo] = useState();
  const [duration, setDuration] = useState();
  const timezone = moment.tz.guess();
  const { t } = useTranslation(["streamManagement"]);
  const [selected, setSelected] = useState([]);

  const dispatch = useDispatch();

  const handleGetStreamById = async (streamInfor) => {
    const dataStreamId = await streamService.getStreamById(streamInfor.id);
    form.setFieldsValue(dataStreamId.data.data);
    setVideoSrc(dataStreamId.data.data.videoUrl);
    setSelected(dataStreamId.data.data.tags);
  };

  useEffect(() => {
    if (type === "edit") {
      handleGetStreamById(streamInfor);
    }
  }, []);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const handleAddStream = async (form) => {
    const addStream = await streamService.addStream(form);
    if(addStream.data.status="success"){
      props.onUpdate();
      openNotificationWithIcon(
        "success",
        "Add stream successfully!"
      );
    }
    setLoading(false)
    closeModal()
  };
  const handleUpdateStream = async (form) => {
    setLoading(true);
    const updateStream = await streamService.updateStream(streamInfor.id, form);
    if(updateStream.data.status="success"){
      openNotificationWithIcon(
        "success",
        "Update stream successfully!"
      );
      props.onUpdate();
    }
    closeModal()
    setLoading(false)
  };
  const handleUploadVideo = async (param) => {
    try {
      let videoUploadUrl = videoSrc;
      if (video) {
        setLoading(true);
        const result = await uploadService.getVideoUpload(video.size);
        if (result && result.data.data) {
          videoUploadUrl = result.data.data.uri;
          const uploadURL = result.data.data.uploadLink;
          const resultUpload = await uploadVideo(uploadURL, video);
        }
      }
         handleAddStream({
        ...param,
        url: videoUploadUrl,
        duration: duration,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = (values) => {
    if (type === "edit") {
      handleUpdateStream(values);
    } else {
      const param = { ...values, videoUrl: videoSrc };
      // if (!values.title) {
      //   openNotificationWithIcon(
      //     "error",
      //     "Title is required!"
      //   );

      //   setTitleError('red')

      //   return;
      // }

      if (!video) {
        openNotificationWithIcon(
          "error",
          "Video is required!"
        );

        setVideoError('red')

        return;
      }

      if (duration > 4*3600) {
        openNotificationWithIcon(
          "error",
          "Maximum video length is 4 hours!"
        );
        setVideoSrc("");
        setVideo({});

        setVideoError('red')

        return;
      }

      handleUploadVideo(param);
    }
  };
  const options = [];
  const handleChangeTags = (value) => {
  };

  const titleChange = () => {
    const text = form.getFieldValue('title');
    if (text) {
      setTitleError('')
    }
  }

  const validateTag = (tag, exists) => {
    if (_.size(tag) <= 50 && _.size(exists) < 10) {
      return tag;
    }

    openNotificationWithIcon(
      "error",
      "Tag label maximum 50 characters and the maximum number of tags is 10!"
    );
  }

  return (
    <Form
      layout="vertical"
      className="add-new-stream"
      style={{ marginTop: "10px" }}
      form={form}
      onFinish={onFinish}
    >
      <Row gutter={12} justify="space-between">
        <Col span={24}>
          <Form.Item name="title">
            <Input maxLength={150} className={titleError} onChange={titleChange} placeholder="Title" />
          </Form.Item>
          <Divider />

          <UploadVideo
            setVideo={setVideo}
            setVideoSrc={setVideoSrc}
            videoSrc={videoSrc}
            video={video}
            setDuration={setDuration}
            disabled={type === "edit"}
            color={videoError}
            setColor={setVideoError}
          />
          <Divider />

          <Form.Item name="tags">
            <TagsInput
              value={selected}
              onChange={setSelected}
              name="tags"
              placeHolder="Hashtags"
              beforeAddValidate={validateTag}
            />
            {/* <TextArea rows={4} placeholder="Hashtags" /> */}
          </Form.Item>
        </Col>
      </Row>
      <Row
        className="modal-button"
        justify={"end"}
        gutter={12}
        style={{ marginTop: "30px" }}
      >
        <Col span={12}>
          <StanButton onClick={() => closeModal()} type="default">
            <StanTypo
              fontSize={18}
              fontWeight={600}
              content="Close"
              color="black"
            />
          </StanButton>
        </Col>
        <Col span={12}>
          <Button loading={loading} htmlType="submit" type="primary">
            <StanTypo
              fontSize={18}
              fontWeight={600}
              content="Save"
              color="white"
            />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AddEditStreamForm;
