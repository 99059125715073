import { Divider } from "antd";
import { STAN_ARTIST_ROLE } from "common/Constant";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { history } from "../../../../App";
import { STAN_MENUS } from "../../../../common/Menus";
import "./SideBar.css";

export default function SideBar() {
  const [open, setOpen] = useState(false);
  const { listProfile } = useSelector((state) => state.ProfileReducer);
  const Menus = STAN_MENUS;

  const RenderMenus = () => {
    return Menus.map((menu, index) => {
      const roles = menu.roles;
      if (roles.includes(listProfile.role)) {
        return (
          <NavLink
            activeStyle={{
              color: "#FFFFFF",
            }}
            key={index}
            to={menu.path}
          >
            <li
              className={
                window.location.pathname.includes(menu.key)
                  ? "item_active"
                  : "item"
              }
              key={index}
            >
              <div className="SideBar-list-item">
                {window.location.pathname.includes(menu.key)
                  ? menu.iconWhite
                  : menu.icon}{" "}
                {menu.title}
              </div>
            </li>
          </NavLink>
        );
      }
    });
  };

  return (
    <>
      <div
        onMouseLeave={() => setOpen(false)}
        // className={open ? "SideBar" : "SideBar-collapsed"}
        className="SideBar"
      >
        <div className="SideBar-logo" onMouseOver={() => setOpen(true)}>
          <img
            onClick={() => history.push("/dashboard")}
            alt=""
            src="/svg/stan_Logo.svg"
          />
        </div>
        {/* {open ? ( */}
          <div className="SideBar-list">
            <div>
              <ul>
                <RenderMenus />
              </ul>
              <Divider />
            </div>
          </div>
        {/* ) : null} */}
      </div>
    </>
  );
}
