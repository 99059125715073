import React from "react";
import { notification } from "antd";
import "./index.css";
export const openSuccessToast = (title, desc, onClick) => {
  notification.success({
    message: title,
    description: desc,
    onClick: () => {},
  });
};

export const openErrorToast = (title, desc, onClick) => {
  notification.error({
    message: title,
    description: desc,
    onClick: () => {},
  });
};
