import { GET_LIST_POST, GET_REPORT_HISTORY, VIEW_DETAIL_POST } from "../types/PostManagementType";

const initialState = {
  postDetail: {},
  postDetailMedia: [],
  listPost: [],
  totalPost: 0,
  reportHistory: [],
  totalReportHistory: 0
};

export const PostManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIEW_DETAIL_POST:
      return {
        ...state,
        postDetail: action.payload.postDetail,
        postDetailMedia: action.payload.postDetailMedia
      };
    case GET_LIST_POST:
      return {
        ...state,
        listPost: action.payload.listPost,
        totalPost: action.payload.totalPost,
      };
      case GET_REPORT_HISTORY:
        return {
          ...state,
          reportHistory: action.payload.reportHistory,
          totalReportHistory: action.payload.totalReportHistory
        }
    default:
      return state;
  }
};
