import { GET_LIST_STREAM } from "../types/StreamType";

const initialState = {
  listStream: [],
};

export const StreamReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_STREAM:
      return {
        ...state,
        listStream: action.payload.listStream,
      };
    default:
      return state;
  }
};
