import { CloseCircleFilled } from "@ant-design/icons";
import { Col, Form, Row, Upload } from "antd";
import React, { useState } from "react";
import './UploadVideo.css'
import { getVideoDuration } from "common/common";
import ReactPlayer from "react-player";
const UploadVideo = (props) => {
    const {setDuration,setVideo,setVideoSrc,videoSrc,video,disabled, color, setColor}=props

  const handleChange =  async ({ file }) => {
    const videoDuration = await getVideoDuration(file.originFileObj);
    setDuration(videoDuration)
    setVideo(file.originFileObj);
    var reader = new FileReader();
    var url = URL.createObjectURL(file.originFileObj);
    setVideoSrc(url);
    setColor('');
  };
  const unSelectVideo = () => {
    setVideoSrc("");
    setVideo({});
  };

  return (
    <Form.Item label="Add media">
        <Upload
          className="mt-3 mb-3 "
          accept=".mp4"
          action={()=>{}}
          itemRender={null}
          listType="picture"
          maxCount={1}
          onChange={handleChange}
          disabled={videoSrc}
        >
          <div className={"upload-container " + color}>
            <Row align="middle" gutter={12}>
              <Col>
                <div>
                  <img src="/svg/add-image.svg" />
                </div>
              </Col>
              <Col>
                <div>Upload</div>
              </Col>
            </Row>
          </div>
        </Upload>
       {videoSrc&&<div className="livestream-video">
          <ReactPlayer url={videoSrc} controls={true}/>
          {!disabled&&<CloseCircleFilled
            onClick={() => {
              unSelectVideo();
            }}
            className="close-button"
            style={{ fontSize: "25px" }}
          />}
        </div>}
    </Form.Item>
  );
};

export default UploadVideo;
