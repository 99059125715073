export const StanLogo = () => {
  return <img alt="" src="/svg/stan_Logo.svg" />;
}

export const DashboardIcon = () => {
  return <img alt="" src="/svg/dashboard-icon.svg" />;
}
export const DashboardIconWhite = () => {
  return <img alt="" src="/svg/dashboard-icon-white.svg" />;
}

export const StreamIcon = () => {
  return <img alt="" src="/svg/stream-icon.svg" />;
}
export const StreamIconWhite = () => {
  return <img alt="" src="/svg/stream-icon-white.svg" />;
}
export const BriefcaseIcon = () => {
  return <img alt="" src="svg/briefcase.svg" />;
}
export const BriefcaseIconWhite = () => {
  return <img alt="" src="svg/briefcase-white.svg" />;
}

export const QuestionIcon = () => {
  return <img alt="" src="svg/question-icon.svg" />;
}

export const SettingsIcon = () => {
  return <img alt="" src="svg/settings-icon.svg" />;
}

export const ChevronDown = () => {
  return <img alt="" src="svg/chevron-down.svg" />;
}

export const BellIcon = () => {
  return <img alt="" src="svg/bell-icon.svg" />;
}

export const SearchIcon = () => {
  return <img alt="" src="svg/search-icon.svg" />;
}

export const EmailIcon = () => {
  return <img alt="" src="svg/email.svg" />;
}

export const LocationIcon = () => {
  return <img alt="" src="svg/location.svg" />;
}

export const PhoneIcon = () => {
  return <img alt="" src="svg/phone.svg" />;
}

export const BrowserIcon = () => {
  return <img alt="" src="svg/browser.svg" />;
}
export const CalendarIcon = () => {
  return <img alt="" src="svg/calendar.svg" />;
}
export const UserIcon = () => {
  return <img style={{ marginRight: "10px" }} alt="" src="svg/user-2.svg" />;
}

export const TranslateIcon = () => {
  return <img style={{ marginRight: "10px" }} alt="" src="svg/translate_24px.svg" />;
}

export const PowerIcon = () => {
  return <img style={{ marginRight: "10px" }} alt="" src="svg/power.svg" />;
}
export const CheckIcon = () => {
  return <img style={{ marginRight: "10px" }} alt="" src="svg/checkIcon.svg" />;
}

// FLAGS
export const VietFlag = () => {
  return <img style={{ marginRight: "10px" }} alt="" src="svg/vieFlag.svg" />;
}

export const KorFlag = () => {
  return <img style={{ marginRight: "10px" }} alt="" src="svg/korFlag.svg" />;
}

export const ThaiFlag = () => {
  return <img style={{ marginRight: "10px" }} alt="" src="svg/thaiFlag.svg" />;
}
export const EngFlag = () => {
  return <img style={{ marginRight: "10px" }} alt="" src="svg/engFlag.svg" />;
}

// PLATFORMS
export const KakaoTalkIcon = () => {
  return <img style={{ marginRight: "10px" }} alt="" src="svg/icons8-kakaotalk.svg" />;
}

export const FacebookIcon = () => {
  return <img style={{ marginRight: "10px" }} alt="" src="svg/icons8-facebook.svg" />;
}

export const GoogleIcon = () => {
  return <img style={{ marginRight: "10px" }} alt="" src="svg/icons8-google.svg" />;
}

export const AppleIcon = () => {
  return <img style={{ marginRight: "10px" }} alt="" src="svg/icons8-apple.svg" />;
}

export const NaverIcon = () => {
  return <img style={{ marginRight: "10px" }} alt="" src="svg/icons8-naver.svg" />;
}

export const LineIcon = () => {
  return <img style={{ marginRight: "10px" }} alt="" src="svg/icons8-line.svg" />;
}

// ANNOTATIONS
export const InfoCircleIcon = () => {
  return <img width={45} alt="" src="svg/info-circle.svg" />;
}

export const WarningIcon = () => {
  return <img width={45} alt="" src="svg/warning_24px.svg" />;
}

// ACTION FORMATTER
export const ViewIcon = () => {
  return <img alt="" src="svg/view_24px.svg" />;
}

export const EditIcon = () => {
  return <img alt="" src="svg/edit_24px.svg" />;
};

export const DeleteIcon = () => {
  return <img alt="" src="svg/trash.svg" />;
};

export const MoreIcon = () => {
  return <img alt="" src="svg/more-horizontal.svg" />;
}

export const SwitchIcon = () => {
  return <img style={{ marginRight: "10px" }} alt="" src="svg/repeat.svg" />;
}

export const SortIcon = () => {
  return <img style={{ marginRight: "10px" }} alt="" src="svg/sort.svg" />;
}

export const RemoveIcon = () => {
  return (
    <img
      style={{ marginRight: "10px" }}
      alt=""
      src="svg/remove_circle_outline_24px.svg"
    />
  );
};

// STATUS FORMAT
export const LockIcon = () => {
  return <img alt="" src="svg/lock_24px.svg" />;
};

export const LockOpenIcon = () => {
  return <img alt="" src="svg/lock_open_24px.svg" />;
};