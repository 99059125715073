import Cookies from "js-cookie";
import _ from "lodash";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route } from "react-router-dom";
import { history } from "../../App";
import Header from "./Layout/Header/Header";
import SideBar from "./Layout/SideBar/SideBar";

export const HomeTemplate = (props) => {
  const { t } = useTranslation(["common"]);
  const { Component, ...restProps } = props;
  const [tokenCheck, setTokenCheck] = useState(Cookies.get("TokenAW"));

  useEffect(() => {
    if (!tokenCheck) {
      history.push("/login");
    } else {
      let jwtRegex = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/;
      if (!_.isUndefined(tokenCheck) && !jwtRegex.test(tokenCheck)) {
        history.push("/login");
      }
    }
  }, [tokenCheck]);

  return (
    <Route
      {...restProps}
      render={(propsRoute) => {
        return (
          <Fragment>
            <div className="StanLayout">
                <SideBar />
              <div className="StanLayout-content">
                <Header {...propsRoute} />
                <Component {...propsRoute} />
              </div>
            </div>
          </Fragment>
        );
      }}
    />
  );
};
