import { useState } from "react";
import { openSuccessToast } from "../../atomics/StanToast";
import { postManagementService } from "../../services/postManagementService";
import { uploadService, uploadVideo } from "../../services/uploadMediaService";
import { ActionType } from "../../redux/types/PopupAddEditType";
import _ from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { SET_POST_STATE } from "../../redux/types/PopupAddEditType";
import { getMMDDYYYY, getHHMMSS } from "../../util/helper";
import moment from "moment";
import { DATE_FORMAT, TIME_FORMAT } from "common/Constant";
export default function useAddAndEditPost(
  onClose,
  actionType,
  onSuccess,
  postId
) {
  const [listUserFollow, setListUserFollow] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  // const [listUserSelected, setListUserSelected] = useState([]);
  const { postState } = useSelector((state) => {
    return state.PopupAddEditReducer;
  });
  const listUserSelected = listUserFollow.filter((item) => {
    return postState?.taggedUserIds?.indexOf(item.id) >= 0;
  });
  const dispatch = useDispatch();

  const fetchListUserFollow = async () => {
    const res = await postManagementService.getListUserFollow();
    setListUserFollow(res?.data?.data?.users);
  };
  const addPost = async (post, isSquare) => {
    post.startTime = getStartTimeFromDateTime(post.date, post.time);
    setIsloading(true);
    // const listId = listUserSelected.map((user) => user.value);
    // post["taggedUserIds"] = listId;
    let filePaths = [];
    Promise.all(
      post.listImgs?.map(async (item) => {
        if (item.type === "video/mp4") {
          const result = await uploadService.getVideoUpload(item.size);
          if (result && result.data.data) {
            const videoUploadUrl = result.data.data.uri;
            const uploadURL = result.data.data.uploadLink;
            const resultUpload = await uploadVideo(uploadURL, item);

            return filePaths.push({
              type: "video",
              path: videoUploadUrl,
            });
          }
        }
        const square = isSquare
          ? getSmallerValue(item.width, item.height)
          : null;

        let uploadRes = await uploadService.uploadFile(item.type, item);

        filePaths.push({
          type: "image",
          path: uploadRes.path,
          width: square || item.width,
          height: square || item.height,
        });
      })
    ).then(async () => {
      post.filePaths = filePaths;
      delete post.id;
      const res = await postManagementService.addPost(
        _.omit(post, ["listImgs"])
      );
      setIsloading(false);
      if (res.status === "success") {
        openSuccessToast("Success", "Post added successfully!");
        onClose();
        onSuccess();
      }
    });
  };

  const updatePost = async (post) => {
    post.startTime = getStartTimeFromDateTime(post.date, post.time);
    console.log("POST EDIT DATA =>", post);
    const res = await postManagementService.updatePost(post);
    if (res.status === "success") {
      openSuccessToast("Success", "Post updated successfully!");
      onSuccess();
      onClose();
    }
  };
  const getDetailPost = async () => {
    if (!postId) return;
    const res = await postManagementService.getDetailPost(postId);
    const data = res.data?.data;
    let listImgs = [];
    let taggedUserIds = data?.taggedUsers?.users?.map((user) => user.id) || [];
    data?.media?.forEach((item) => {
      listImgs.push({
        thumbUrl: item.fileType === "image" ? item?.file?.thumb : item?.thumb,
      });
    });
    const date = data?.detail?.startTime
      ? moment(new Date(data?.detail?.startTime * 1000)).format(DATE_FORMAT)
      : "";
    const time = data?.detail?.startTime
      ? getHHMMSS(data?.detail?.startTime * 1000)
      : "";
    const newPostState = {
      ...postState,
      taggedUsers: data?.taggedUsers,
      listImgs: [...listImgs],
      taggedUserIds: [...taggedUserIds],
      title: data?.detail?.title,
      description: data?.detail?.description,
      startTime: data?.detail?.startTime,
      isPublic: data?.detail?.startTime ? true : false,
      date,
      time,
    };
    data &&
      dispatch({
        type: SET_POST_STATE,
        payload: {
          postState: newPostState,
        },
      });
  };
  const getSmallerValue = (a, b) => {
    if (a < b) return a;
    return b;
  };

  const getStartTimeFromDateTime = (date, time) => {
    if (date === "") return null;
    const dateToSecond = moment(date, DATE_FORMAT).unix();
    let timePayload = time;
    if (typeof time === "string" || typeof timePayload === "number") {
      timePayload = moment(timePayload, TIME_FORMAT);
    }
    const timeToSecond = timePayload.diff(
      timePayload.clone().startOf("day"),
      "seconds"
    );
    console.log("time", time);
    const startTime = dateToSecond + timeToSecond;
    console.log("startTime", startTime);
    return startTime;
  };
  const onSave = actionType === ActionType.addPost ? addPost : updatePost;
  return {
    onSave,
    listUserFollow,
    fetchListUserFollow,
    isLoading,
    getDetailPost,
    listUserSelected,
  };
}
