import React from "react";
import "antd/dist/antd.css";
import "./date-time-input.css";
import { TimePicker } from "antd";
import moment from "moment";

const StandTimeInput = ({
  className,
  onChange,
  placeholder,
  value,
  disabled,
  required,
  defaultValue,
  format,
  disabledTime
}) => (
  <div className={`${className} date-time-input-wrapper`}>
    <TimePicker
      size="large"
      onChange={onChange}
      placeholder={placeholder}
      value={value?._isValid ? value : null}
      disabled={disabled}
      required={required}
      defaultValue={defaultValue}
      format={format}
      disabledTime={disabledTime}
    />
  </div>
);

export default StandTimeInput;
