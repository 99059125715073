import { PAGE_SIZE } from "common/Constant";
import { useState } from "react";
import { postManagementService } from "../../services/postManagementService";

export default function usePostManage() {
  const [listPost, setListPost] = useState([]);
  const [page, setPage] = useState(1);
  const pageSize=PAGE_SIZE;
  const [loading,setLoading]=useState(true)
  const [totalItems, setTotalItems] = useState(0);
  const [sort, setSort] = useState("DESC");
  const [filterOption, setFilterOption] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    type: "",
    status: null,
  });
  const fetchListPost = async () => {
    setLoading(true)
    let res = await postManagementService.getListPost(
      filterOption,
      pageSize,
      page,
      sort
    );
    setLoading(false)
    setListPost(res?.data?.data?.items);
    setTotalItems(res?.data?.data?.totalItems);
    if(!res.data?.data?.items || res.data?.data?.items.length === 0) {
      setPage(1)
    }
  };
  const deletePost = async (idList) => {
    let res = await postManagementService.deletePost({
      postIds: idList,
      status: 2
    })
    if(res.data.status === "success") {
      fetchListPost();
    } else {
    }
  }
  const handleTableChange = (
    pagination,
    filters,
    sorter
  ) => {
    setPage(pagination.current)
  }
  const onSort = () => {
    if (sort === "DESC") setSort("ASC");
    else setSort("DESC");
  };
  return {
    loading,
    listPost,
    page,
    pageSize,
    filterOption,
    setFilterOption,
    fetchListPost,
    totalItems,
    handleTableChange,
    deletePost,
    onSort,
    sort
  };
}
