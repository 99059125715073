import { baseService } from "./baseService";
import { handleImagesSize } from "../util/helper";
export class PostManagementService extends baseService {
  getListPost = (filter, pageSize, page, sort) => {
    const filterOption = {...filter}
    filterOption.fromDate = typeof(filterOption.fromDate) === 'string' || !filterOption.fromDate ? filterOption.fromDate : filterOption.fromDate.format('DD/MM/YYYY');
    filterOption.toDate = typeof(filterOption.toDate) === 'string' || !filterOption.toDate ? filterOption.toDate : filterOption.toDate.format('DD/MM/YYYY');
    let url = `api/v1/artist-web/posts/list?limit=${pageSize}&offset=${
      (page - 1) * pageSize
    }`;
    if (filterOption.fromDate && filterOption.fromDate !== "") {
      url += `&from=${filterOption.fromDate}`;
    }
    if (filterOption.toDate && filterOption.toDate !== "") {
      url += `&to=${filterOption.toDate}`;
    }
    if (filterOption.status !== null) {
      if(filterOption.status === "IMAGE" || filterOption.status === "VIDEO") {
        url += `&mediaType=${filterOption.status}`;
      } else {
        url += `&status=${filterOption.status}`;
      }
    }
    if (filterOption.search && filterOption.search !== "") {
      url += `&keyword=${filterOption.search}`;
    }
    // if (data.timezone && data.timezone !== "") {
      url += `&timezone=${filterOption.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone}&sort=CREATED_AT&order=${sort}`;
    // }
    return this.get(url);
  };

  getDetailPost = (postId) => {
    return this.get(`api/v1/artist-web/posts/${postId}`);
  };
  addPost = async (post) => {
    const url = "api/v1/artist-web/posts/add-new";
    post.startTime = Math.round(post.startTime)
    post.filePaths = handleImagesSize(post.filePaths);
    post.displayWidth = post.filePaths[0].width;
    post.displayHeight = post.filePaths[0].height;
    const res = await this.post(url, post);
    return res.data
  };
  updatePost = async (post) => {
    const url = `api/v1/artist-web/posts/${post.id}/update`;
    post.startTime = Math.round(post.startTime);
    const res = await this.put(url, post);
    return res?.data;
  }
  getReportHistory = (postId, pageSize, page) => {
    return this.get(
      `api/v1/artist-web/posts/report-history?postId=${postId}&limit=${pageSize}&offset=${
        (page - 1) * pageSize
      }`
    );
  };
  getListUserFollow = async () => {
    let url = `api/v1/artist-web/user/all-users?isFollowing=true`
    // let url = `api/v1/artist-web/user/all-users`
    return this.get(url);
  }
  deletePost = (data) => {
    return this.put(`api/v1/artist-web/posts/status`, data);
  }
}

export const postManagementService = new PostManagementService();
