import { Table, Row, Col } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import * as _ from "lodash";
import ActionFormatter from "../../../../component/ActionFormatter/ActionFormatter";
import ActionStop from "../../../../component/ActionFormatter/ActionStop";
import StreamModal from "../StreamModal/StreamModal";
import StanStatusBadge from "../../../../atomics/StanStatusBadge";
import { streamService } from "../../../../services/streamService";
import { STANDARD_STATUS } from "../../../../util/common-types";
import { openSuccessToast } from "../../../../atomics/StanToast";
import StanButton from "component/StanButton/StanButton";
import StanTypo from "component/StanTypo/StanTypo";
import AlertWarning from "component/AlertWarning";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

const StreamTable = (props) => {
  const {
    loading,
    deleteStream,
    onEditRow,
    dataSource,
    pagination,
    handleTableChange,
    setLoading,
    onSort
  } = props;

  let history = useHistory();
  const [isOpenStreamModal, setIsOpenStreamModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState([]);
  const [streamInfor, setStreamInfor] = useState();

  const onStopStream = async (stream) => {
    await streamService.stopStream(stream.id);
    openSuccessToast("Success!", "Stopped successfully!");
    props.onUpdateDateSource();
  };
  const closeStreamModal = () => {
    setIsOpenStreamModal(false);
  };
  const openStreamModal = (streamInfor) => {
    setStreamInfor(streamInfor);
    setIsOpenStreamModal(true);
  };
  const handleDelete = async (stream) => {
    deleteStream([stream.id]);
  };

  const [isShowModal, setIsShowModal] = useState(false);
  const handleCancel = () => setIsShowModal(false);
  const handleOpen = () => setIsShowModal(true);
  const renderSort = (dir) => {
    if (dir && dir === "ascend") {
      return (
        <span className="custom-sort">
          <ArrowUpOutlined />
        </span>
      );
    } else if (dir && dir === "descend") {
      return (
        <span className="custom-sort">
          <ArrowDownOutlined />
        </span>
      );
    } else {
      return (
        <span className="custom-sort" style={{ opacity: 0.5 }}>
          <ArrowUpOutlined />
          <ArrowDownOutlined />
        </span>
      );
    }
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Title",
      dataIndex: "title",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Creator",
      dataIndex: "ownerName",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <a>
          <StanStatusBadge status={status} />
        </a>
      ),
    },
    {
      title: "Record time",
      dataIndex: "recordTime",
      render: (text) => (
        <a style={{ display: "block", textAlign: "center" }}>
          {moment().hours(0).minutes(0).seconds(text).format("mm:ss")}
        </a>
      ),
    },
    {
      title: "Viewers",
      dataIndex: "totalViewers",
      render: (text) => (
        <a style={{ display: "block", textAlign: "center" }}>{text}</a>
      ),
    },
    {
      dataIndex: "createdAt",
      render: (text) => <a>{text}</a>,
      title: (
        <span style={{ color: "#FFF" }}>
          <img
            className="table-sort-icon"
            onClick={onSort}
            src="svg/table/sort-icon.svg"
          />{" "}
          Created at
        </span>
      ),
    },
    {
      title: "Actions",
      render: (text, row) => {
        const Action =
          row.status === STANDARD_STATUS.onGoing ? (
            <ActionStop onClick={() => onStopStream(row)} />
          ) : (
            <ActionFormatter
              showView
              showEdit
              showDelete
              handleEdit={() => openStreamModal(row)}
              handleView={() => history.push(`/stream-management/${row.id}`)}
              handleDelete={() => handleDelete(row)}
            />
          );
        return Action;
      },
    },
  ];
  
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedPost(selectedRows);
    },
  };

  return (
    <>
      {_.size(selectedPost) > 0 && (
        <Row style={{ marginBottom: "15px" }}>
          <StanButton onClick={() => handleOpen()} danger type="primary">
            <StanTypo color="white" content="Delete" />
          </StanButton>
          <AlertWarning
            onOK={() => {
              handleCancel();
              setLoading(true);
              const ids = _.map(selectedPost, (p) => p.id);
              let totalPage = parseInt(pagination.total / pagination.pageSize);
              const termPage = pagination.total % pagination.pageSize;
              totalPage = totalPage + (termPage > 0 ? 1 : 0);
              let currentPage = pagination.current;
              if (
                totalPage === currentPage &&
                currentPage > 1 &&
                _.size(ids) === termPage
              ) {
                currentPage--;
              }
              setSelectedPost([]);
              deleteStream(ids, currentPage);
              // pagination.current = currentPage;

              // onSetPage(currentPage);
            }}
            onCancel={handleCancel}
            danger={true}
            type={"primary"}
            desc={"Are you sure you want to delete the selected option(s)?"}
            isCancelButton={true}
            isOpen={isShowModal}
            btnText={"Yes"}
            cancelText={"No"}
          />
        </Row>
      )}
      <Table
        rowKey={(row) => row.id}
        rowSelection={{
          ...rowSelection,
        }}
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
        onChange={handleTableChange}
        loading={loading}
      />
      {isOpenStreamModal && (
        <StreamModal
          closeModal={closeStreamModal}
          isOpenModal={isOpenStreamModal}
          type="edit"
          streamInfor={streamInfor}
          onUpdate={onEditRow}
        />
      )}
    </>
  );
};

export default StreamTable;
