import { Col, Row } from "antd";
import React from "react";
import StanTypo from "../../StanTypo/StanTypo";

const ListInteraction = (props) => {
    const {item, index}=props
  return (
    <Col key={`ListInteraction ${item.value}`} span={index % 2 === 0 ? 8 : 16}>
      <Row gutter={12} justify={"start"}>
        <Col span={index % 2 === 0 ? 8 : 5}>
          <StanTypo
            fontSize={14}
            fontWeight={400}
            color="#8D8D8D"
            content={item.lable}
          />
        </Col>
        <Col>
          {" "}
          <StanTypo
            fontSize={14}
            fontWeight={600}
            color="#0D0D0D"
            content={item.value}
          />
        </Col>
      </Row>
    </Col>
  );
};

export default ListInteraction;
