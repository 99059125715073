import { Image, Table, Breadcrumb } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "./PostManagement.css";
import { useDispatch, useSelector } from "react-redux";
import { DATE_TIME_FORMAT, FILE_TYPES } from "../../common/Constant";
import {
  getDetailPost,
  getReportHistory,
} from "../../redux/action/PostManagementAction";
import "./PostManagement.css";
import ViewDetail from "component/ViewDetail/ViewDetail";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";
import { VIEW_DETAIL_POST } from "../../redux/types/PostManagementType";

export default function PostDetail(props) {
  const { postId } = useParams();
  const [page, setPage] = useState(1);
  const audioRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation(["postManagement"]);
  const { postDetail, postDetailMedia, reportHistory, totalReportHistory } =
    useSelector((state) => state.PostManagementReducer);
  const postDetailValue = postDetail?.detail;
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("TABLE.REPORT_HISTORY.REPORTER"),
      dataIndex: "name",
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("TABLE.REPORT_HISTORY.EMAIL"),
      dataIndex: "email",
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("TABLE.REPORT_HISTORY.DATE_REPORTED"),
      dataIndex: "createdAt",
      render: (text) => (
        <div style={{ textAlign: "center" }}>
          {moment(text).format(DATE_TIME_FORMAT)}
        </div>
      ),
    },
    {
      title: t("TABLE.REPORT_HISTORY.REASON"),
      dataIndex: "reason",
      render: (text) => <div style={{ textAlign: "center" }}>{text}</div>,
    },
  ];

  useEffect(() => {
    // CALL API POST DETAIL / REPORT HISTORY
    dispatch(getDetailPost(postId));
    dispatch(getReportHistory(postId, 10, page));
  }, [postId, page]);
  const pageSize = 10;
  const pagination = {
    current: page,
    pageSize,
    total: totalReportHistory,
  };
  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };
  useEffect(() => {
    return dispatch({
      type: VIEW_DETAIL_POST,
      payload: {
        postDetail: {},
        postDetailMedia: [],
      },
    });
  }, []);

  const handlePlayAudio = () => {
    if (
      audioRef.current.paused &&
      audioRef.current.currentTime > 0 &&
      !audioRef.current.ended
    ) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  };

  const startTime = postDetailValue?.startTime
    ? postDetailValue?.startTime
    : new Date(postDetailValue?.createdAt).getTime() / 1000;
  const listData = [
    {
      lable: t("TITLE.POST_DESCRIPTION"),
      value: postDetailValue?.description || "--",
    },
    {
      lable: t("TITLE.CREATOR"),
      value: postDetailValue?.creator ? postDetailValue?.creator : "--",
    },
    {
      lable: t("TITLE.CREATED_DATE"),
      value: postDetailValue?.createdAt
        ? moment(postDetailValue?.createdAt).format(DATE_TIME_FORMAT)
        : "--",
    },
    {
      lable: t("TITLE.PUBLIC_DATE"),
      // value: stream?.recordTime ?? "--",
      value: moment.unix(startTime).format(DATE_TIME_FORMAT),
    },
    {
      lable: t("TITLE.STATUS"),
      value: postDetailValue?.status,
    },
  ];
  const listInteractions = [
    {
      lable: t("TITLE.LIKES"),
      value: postDetailValue?.totalLikes ?? "--",
    },
    {
      lable: t("TITLE.COMMENTS"),
      value: postDetailValue?.totalComments ?? "--",
    },
    {
      lable: t("TITLE.POINTS"),
      value: postDetailValue?.points ?? "--",
    },
    {
      lable: t("TITLE.REPORTS"),
      value: postDetailValue?.totalReport ?? "--",
    },
    // {
    //   lable: t("TITLE.LINK"),
    //   value:postDetailValue?.link ?? "--",
    // },
  ];

  return (
    <>
      <div style={{ minHeight: "100vh" }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <NavLink to="/post-management">Post Management</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>View Post Detail</Breadcrumb.Item>
        </Breadcrumb>
        {/* POST DETAIL */}
        <div className="PostDetail">
          {postDetailMedia[0]?.fileType === FILE_TYPES.IMAGE && (
            <div
              className="postImage"
              style={{
                background: `url('${
                  postDetailMedia.length > 0
                    ? postDetailMedia[0].originUrl
                    : "/img/avatar-default.png"
                }')`,
              }}
            ></div>
          )}
          {postDetailMedia[0]?.fileType === FILE_TYPES.AUDIO && (
            <div className="PostMediaWrapper" onClick={handlePlayAudio}>
              <img
                src={
                  postDetailMedia.length > 0
                    ? postDetailMedia[0].thumb
                    : "/img/avatar-default.png"
                }
              />
              <audio
                ref={audioRef}
                controls
                autoPlay
                className="audio-control"
                src={postDetailMedia[0]?.originUrl}
              ></audio>
            </div>
          )}
          {postDetailMedia[0]?.fileType === FILE_TYPES.VIDEO && (
            <div className="post-video">
              <ReactPlayer
                url={postDetailMedia[0]?.filePath}
                width="450px"
                height="auto"
                controls={true}
              />
            </div>
          )}
          <div className="postInfo">
            <ViewDetail
              listInteractions={listInteractions}
              listData={listData}
              type={t("TITLE.POST_DETAIL")}
            />
          </div>
        </div>

        {/* REPORT HISTORY */}
        <div className="ReportHistory">
          <h2>{t("TITLE.REPORT_HISTORY")}</h2>
          <Table
            columns={columns}
            onChange={handleTableChange}
            pagination={pagination}
            dataSource={reportHistory}
          />
        </div>
      </div>
    </>
  );
}
