import { Button } from "antd";

const StanButton = (props) => {
  return (
    <Button {...props}>
      <div className="d-flex justify-content-center align-items-center">
        {props.children}
      </div>
    </Button>
  );
};

export default StanButton;
