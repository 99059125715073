import { Form } from "antd";
// import StanIconButton from 'component/StanIconButton/StanIconButton'

import { useTranslation } from "react-i18next";
import { RULES } from "../../../../../common/Constant";
import StanIconButton from "../../../../../component/StanIconButton/StanIconButton";
import StanTypo from "../../../../../component/StanTypo/StanTypo";
const InputItem = ({ children, icon, name, label,isDisable }) => {
  const rules = RULES();
  const { t } = useTranslation(["profile"]);
  return (
    <div className={`infoItem `+ (isDisable?'background-disable':'')}>
      <StanIconButton icon={icon} />
      <div className="ml-3   w-100">
        <Form.Item
          rules={rules[`${name}`] ?? []}
          label={<StanTypo fontSize={14} fontWeight={400} content={label} />}
          name={name}
        >
          {children}
        </Form.Item>
      </div>
    </div>
  );
};

export default InputItem;
