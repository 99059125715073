import { Col, Row, Breadcrumb } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "./StreamDetail.css";
import ViewDetail from "../../../../component/ViewDetail/ViewDetail";
import { streamService } from "../../../../services/streamService";
import ReactPlayer from "react-player";
import { NavLink } from "react-router-dom";

export default function PostDetail(props) {
  const { postId } = useParams();
  const { t } = useTranslation(["streamManagement"]);
  const [stream, setStream] = useState();
  const params = useParams();

  const getDetailData = async () => {
    const detailData = await streamService.getStreamById(params.id);
    setStream(detailData?.data?.data);
  };
  useEffect(() => {
    getDetailData();
  }, []);
  useEffect(() => {}, [postId]);

  const listData = [
    {
      lable: t("TITLE.TITLE"),
      value: stream?.title ?? "--",
    },
    {
      lable: t("TITLE.CREATOR"),
      value: stream?.ownerName ?? "--",
    },
    {
      lable: t("TITLE.CREATED_DATE"),
      value: stream?.createdAt ?? "--",
    },
    {
      lable: t("TITLE.RECORD_TIME"),
      value: stream?.recordTime ?? "--",
    },
    {
      lable: t("TITLE.TAG"),
      value:stream?.tags?? ["--"]
      // value: "--",
    },
    {
      lable: t("TITLE.STATUS"),
      value: stream?.status ?? "--",
    },
  ];
  const listInteractions = [
    {
      lable: t("TITLE.VIEWER"),
      value: stream?.totalViewers ?? "--",
    },
    {
      lable: t("TITLE.POINTS"),
      value: stream?.points ?? "--",
    },
    {
      lable: t("TITLE.LIKES"),
      value: stream?.totalLikes ?? "--",
    },
    {
      lable: t("TITLE.COMMENTS"),
      value: stream?.totalComments ?? "--",
    },
  ];
  return (
    <>
      <div style={{ minHeight: "100vh" }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <NavLink to="/stream-management">Stream Management</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>View stream detail</Breadcrumb.Item>
        </Breadcrumb>
        {/* POST DETAIL */}
        <Row className="streamDetail" gutter={24}>
          <Col span={8} className='video-detail'>
              <ReactPlayer url={stream?.videoUrl} controls={true}/>
          </Col>
          <Col span={16}>
              <ViewDetail
                listInteractions={listInteractions}
                listData={listData}
                type= {t("TITLE.STREAM_DETAIL")}
              />
          </Col>
        </Row>
      </div>
    </>
  );
}
