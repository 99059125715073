import { Popconfirm } from "antd";
import AlertWarning from "component/AlertWarning";
import { useState, useTransition } from "react";
import { DeleteIcon, EditIcon, ViewIcon } from "../../common/commonSvg";
import "./ActionFormatter.css";
export default function ActionFormatter(props) {
  const {
    showView,
    showEdit,
    showDelete,
    handleView,
    handleEdit,
    handleDelete,
  } = props;
  const RenderView = () => {
    if (showView) {
      return (
        <div className="ActionItem" onClick={() => handleView && handleView()}>
          <ViewIcon />
        </div>
      );
    }
    return null;
  };

  const RenderEdit = () => {
    if (showEdit) {
      return (
        <div className="ActionItem" onClick={() => handleEdit && handleEdit()}>
          <EditIcon />
        </div>
      );
    }
    return null;
  };

  const RenderDelete = () => {
    // const {t}=useTransition()
    const [isShowModal, setIsShowModal] = useState(false);
    const handleCancel = () => setIsShowModal(false);
    const handleOpen = () => setIsShowModal(true);
    if (showDelete) {
      return (
        // <Popconfirm
        //   title="Are you sure to delete this post?"
        //   onConfirm={() => handleDelete && handleDelete()}
        //   okText="Yes"
        //   cancelText="No"
        // >
        //   <div className="ActionItem">
        //     <DeleteIcon />
        //   </div>
        // </Popconfirm>
        <div className="delete-warning-modal">
          <div onClick={handleOpen} className="ActionItem">
            <DeleteIcon />
          </div>
          <AlertWarning
            onOK={() => {
              handleDelete && handleDelete();
              handleCancel();
            }}
            onCancel={handleCancel}
            danger={true}
            type={"primary"}
            desc={"Are you sure to delete this item?"}
            isCancelButton={true}
            isOpen={isShowModal}
            btnText={"Remove"}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div className="d-flex justify-content-around">
        <RenderView />
        <RenderEdit />
        <RenderDelete />
      </div>
    </>
  );
}
