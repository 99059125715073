import {
  BriefcaseIcon,
  BriefcaseIconWhite,
  DashboardIcon,
  DashboardIconWhite,
  instaSvg,
  QuestionIcon,
  SettingsIcon,
  StreamIcon,
  StreamIconWhite,
} from "./commonSvg";
import { STAN_ARTIST_ROLE } from "./Constant";

export const STAN_MENUS = [
  {
    title: "Dashboard",
    key: "dashboard",
    icon: <DashboardIcon />,
    iconWhite: <DashboardIconWhite />,
    path: "/dashboard",
    roles: [STAN_ARTIST_ROLE.FAN, STAN_ARTIST_ROLE.ARTIST],
  },
  {
    title: "Stream management",
    key: "stream-management",
    icon: <StreamIcon />,
    iconWhite: <StreamIconWhite />,
    path: "/stream-management",
    roles: [STAN_ARTIST_ROLE.ARTIST],
  },
  {
    title: "Post management",
    key: "post-management",
    icon: <BriefcaseIcon />,
    iconWhite: <BriefcaseIconWhite />,
    path: "/post-management",
    gap: true,
    roles: [STAN_ARTIST_ROLE.ARTIST],
  },
];

export const STAN_FUNCTION_MENU = [
  {
    title: "Help",
    key: "help",
    icon: <QuestionIcon />,
    path: "/help",
  },
  {
    title: "Settings",
    key: "settings",
    icon: <SettingsIcon />,
    path: "/settings",
  },
];
