import { notification } from "antd";
import { createBrowserHistory } from "history";
import Cookies from "js-cookie";
import _ from "lodash";                                                                     
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import "./App.css";
import Dashboard from "./pages/Dashboard/Dashboard";
import PostDetail from "./pages/PostManagement/PostDetail";
import PostManagement from "./pages/PostManagement/PostManagement";
import StreamManagement from "./pages/StreamManagement/StreamManagement";
import UserProfile from "./pages/UserProfile/UserProfile";
import { HomeTemplate } from "./template/HomeTemplate/HomeTemplate";

import TestPage from "./pages/TestPage";
import Login from "./pages/Login/Login";
import StreamDetail from "./pages/StreamManagement/StreamComponent/StreamDetail/StreamDetail";
require("dotenv").config();

export const history = createBrowserHistory();

export const openNotificationWithIcon = (type, mes, des, onClose) => {
  notification[type]({
    message: mes,
    description: des,
    onClose: onClose,
  });
};

function App() {
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "nftDetail"]);

  return (
    <>
      {/* INIT POPUP */}
      {/* INIT POPUP */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>STAN Artist Web</title>
      </Helmet>
      <Router history={history}>
        <Switch>
          <Route path={"/login"} exact component={Login}/>
          <HomeTemplate
            path={"/post-management/:postId"}
            exact
            Component={PostDetail}
          />
          <HomeTemplate
            path={"/post-management"}
            exact
            Component={PostManagement}
          />
          <HomeTemplate
            path={"/stream-management/:id"}
            exact
            Component={StreamDetail}
          />
          <HomeTemplate
            path={"/stream-management"}
            exact
            Component={StreamManagement}
          />
          <HomeTemplate path={"/profile"} exact Component={UserProfile} />
          <HomeTemplate path={"/test"} exact Component={TestPage} />
          {/*  */}
          <HomeTemplate path="*" exact Component={Dashboard} />
          <HomeTemplate path={"/home/"} exact Component={Dashboard} />
          {/*  */}
        </Switch>
      </Router>
    </>
  );
}

export default App;
