import { Col, Dropdown, Empty, Image, Row, Table } from "antd";
import { openNotificationWithIcon } from "App";
import { DASHBOARD_STATUS, DASHBOARD_TYPE, PAGE_SIZE } from "common/Constant";
import ActionFormatter from "component/ActionFormatter/ActionFormatter";
import AlertWarning from "component/AlertWarning";
import PostEditPopup from "component/PostEditPopup";
import StanButton from "component/StanButton/StanButton";
import moment from "moment";
import StreamModal from "pages/StreamManagement/StreamComponent/StreamModal/StreamModal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { handleSwitchAccount } from "redux/action/UserAction";
import { SET_IS_OPEN } from "redux/types/PopupAddEditType";
import url from "url";
import StanStatusBadge from "../../atomics/StanStatusBadge";
import {
  MoreIcon,
  RemoveIcon,
  SortIcon,
  SwitchIcon,
} from "../../common/commonSvg";
import StanFilter from "../../component/StanFilter/StanFilter";
import StanTypo from "../../component/StanTypo/StanTypo";
import {
  SAVE_LINE_RESPONSE,
  SAVE_NAVER_RESPONSE,
} from "../../redux/types/UserType";
import "./dashboard.css";
import useDashboard from "./useDashboard";

export default function Dashboard() {
  const [order, setOrder] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [alertState, setAlertState] = useState({
    isOpen: false,
    buttonClor: "#D92846",
    btnText: "Remove",
    desc: "Are you sure you want to remove this account?",
    isCancelButton: true,
  });

  const {
    listSchedule,
    listArtist,
    page,
    totalItems,
    filterOption,
    userProfile,
    loading,
    isOpenStreamModal,
    streamInfor,
    setFilterOption,
    fetchListSchedules,
    fetchListArtist,
    handleTableChange,
    removeAccount,
    handleView,
    handleEdit,
    handleDelete,
    handleMultiDelete,
    closeStreamModal,
  } = useDashboard();
  const dispatch = useDispatch();
  const pagination = {
    current: page,
    showSizeChanger: false,
    total: totalItems,
  };

  useEffect(() => {
    if (validateSearch()) {
      fetchListSchedules();
    }
  }, [page, filterOption]);

  useEffect(() => {
    fetchListArtist();
    var callbackURL = window.location.href;
    var urlParts = url.parse(callbackURL, true);
    var query = urlParts.query;
    var hasCodeProperty = Object.prototype.hasOwnProperty.call(query, "code");
    if (hasCodeProperty && query.state !== "naver") {
      dispatch({ type: SAVE_LINE_RESPONSE, linePayload: query });
    }
    if (hasCodeProperty && query.state === "naver") {
      dispatch({ type: SAVE_NAVER_RESPONSE, naverPayload: query });
    }
  }, []);

  function validateSearch() {
    let isValidSearch = true;
    if (
      filterOption.fromDate &&
      filterOption.toDate &&
      moment(filterOption.fromDate).isAfter(moment(filterOption.toDate))
    ) {
      openNotificationWithIcon(
        "error",
        "Error",
        "To date must be after From date"
      );
      isValidSearch = false;
    }
    return isValidSearch;
  }

  function confirmRemoveAccount(artist) {
    setAlertState({ ...alertState, isOpen: false });
    removeAccount(artist.id);
  }

  const ArtistItem = (props) => {
    const { artist } = props;
    return (
      <>
        <div className="artistItem d-flex align-items-center justify-content-between">
          <div className="ellipsisText">
            <Image
              alt=""
              preview={false}
              src={artist.user.avatar}
              fallback="/img/avatar-default.png"
            />
            <StanTypo
              fontSize={16}
              fontWeight={500}
              content={artist.user.name}
            />
          </div>
          <div>
            <Dropdown
              trigger={["click"]}
              disabled={isRequesting}
              dropdownRender={() => {
                return (
                  <>
                    <div className="artistItem-function">
                      <div
                        className="artistItem-function-item"
                        onClick={() => {
                          dispatch(
                            handleSwitchAccount(
                              artist,
                              setIsRequesting,
                              alertState,
                              setAlertState,
                              fetchListArtist
                            )
                          );
                        }}
                      >
                        <SwitchIcon />
                        <StanTypo
                          fontSize={14}
                          fontWeight={500}
                          content="Switch account"
                        />
                      </div>
                      <hr />
                      <div
                        className="artistItem-function-item"
                        onClick={() => {
                          setAlertState({
                            isOpen: true,
                            buttonClor: "#D92846",
                            btnText: "Remove",
                            desc: "Are you sure you want to remove this account?",
                            onOK: () => confirmRemoveAccount(artist),
                            isCancelButton: true,
                          });
                        }}
                      >
                        <RemoveIcon />
                        <StanTypo
                          fontSize={14}
                          fontWeight={500}
                          content="Remove account"
                        />
                      </div>
                    </div>
                  </>
                );
              }}
              placement="bottom"
            >
              <a href="#" onClick={(e) => e.preventDefault()}>
                <MoreIcon />
              </a>
            </Dropdown>
          </div>
        </div>
        <hr />
      </>
    );
  };

  const columns = [
    {
      title: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setOrder(!order);
            setFilterOption({
              ...filterOption,
              sort: "ID",
              order: order ? "DESC" : "ASC",
            });
          }}
        >
          <SortIcon /> ID
        </div>
      ),
      dataIndex: "id",
      align: "center",
      hide: true,
      key: "id",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",

      render: (text, row) => {
        return (
          <div className="post-schedule-title">
            <Image src={row.thumbnail} width={84} />
            <div className="title-text ml-1">{text}</div>
          </div>
        );
      },
    },
    {
      title: "Creator",
      dataIndex: "ownerName",
      key: "ownerName",
      render: (text) => (
        <div
          className="ellipsisText"
          style={{ inlineSize: "100px", overflowWrap: "break-word" }}
        >
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => <a>{text === 4 ? "Stream" : "Post"}</a>,
    },
    {
      title: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setOrder(!order);
            setFilterOption({
              ...filterOption,
              sort: "CREATED_DATE",
              order: order ? "DESC" : "ASC",
            });
          }}
        >
          <SortIcon /> Created Date
        </div>
      ),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (
        <a style={{ textAlign: "center", display: "block" }}>{text}</a>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <a
          style={{
            textAlign: "center",
            display: "block",
            width: "97px",
            margin: "auto",
          }}
        >
          {<StanStatusBadge status={text} />}
        </a>
      ),
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (text, row) => (
        <div className="text-center">
          <ActionFormatter
            showView
            handleView={() => handleView(row)}
            showEdit
            handleEdit={() => handleEdit(row)}
            showDelete
            handleDelete={() => handleDelete(row)}
          />
        </div>
      ),
    },
  ];

  const columnsManager = [
    {
      title: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setOrder(!order);
            setFilterOption({
              ...filterOption,
              sort: "ID",
              order: order ? "DESC" : "ASC",
            });
          }}
        >
          <SortIcon /> ID
        </div>
      ),
      dataIndex: "id",
      align: "center",
      key: "id",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Artist",
      width: 100,
      dataIndex: "ownerName",
      key: "ownerName",
      render: (text) => (
        <div className="ellipsisText" style={{ maxWidth: "180px" }}>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",

      render: (text, row) => {
        return (
          <div className="post-schedule-title">
            <Image src={row.thumbnail} width={84} />
            <div className="title-text ml-1">{text}</div>
          </div>
        );
      },
    },
    {
      title: "Creator",
      dataIndex: "ownerName",
      key: "ownerName",
      render: (text) => (
        <div
          className="ellipsisText"
          style={{ inlineSize: "100px", overflowWrap: "break-word" }}
        >
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => <a>{text === 4 ? "Stream" : "Post"}</a>,
    },
    {
      title: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setOrder(!order);
            setFilterOption({
              ...filterOption,
              sort: "CREATED_DATE",
              order: order ? "DESC" : "ASC",
            });
          }}
        >
          <SortIcon /> Created Date
        </div>
      ),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (
        <a style={{ textAlign: "center", display: "block" }}>{text}</a>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <a
          style={{
            textAlign: "center",
            display: "block",
            width: "97px",
            margin: "auto",
          }}
        >
          {<StanStatusBadge status={text} />}
        </a>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedItems([...selectedRows]);
    },
  };

  return (
    <>
      <div style={{ minHeight: "100vh" }}>
        <StanFilter
          isDashboard={true}
          filterOption={filterOption}
          searchPlaceholder="Search by Title, Creator"
          optionsStatus={DASHBOARD_STATUS}
          optionsType={DASHBOARD_TYPE}
          setFilterOption={setFilterOption}
        />
        <div
          className="mb-3"
          style={selectedItems.length < 1 ? { display: "none" } : null}
        >
          <StanButton
            type="primary"
            danger
            onClick={() =>
              setAlertState({
                isOpen: true,
                buttonClor: "#D92846",
                btnText: "Delete",
                desc: "Are you sure you want to delete selected items?",
                isCancelButton: true,
                onOK: () => {
                  handleMultiDelete(selectedItems);
                  setAlertState({ ...alertState, isOpen: false });
                  setSelectedItems([]);
                },
              })
            }
          >
            <StanTypo
              fontSize={14}
              fontWeight={500}
              color="#FFFFFF"
              content="Delete"
            />
          </StanButton>
        </div>
        <Row>
          <Col
            xxl={userProfile?.isManager ? { span: 17 } : { span: 24 }}
            xl={userProfile?.isManager ? { span: 18 } : { span: 24 }}
          >
            <div className="dashboard-list-container">
              <div className="dashboard-list-title">
                Post & Stream Schedule{" "}
              </div>
              <Table
                rowKey="id"
                locale={{
                  emptyText: <Empty description="No results were found" />,
                }}
                columns={userProfile.isManager ? columnsManager : columns}
                dataSource={listSchedule}
                rowSelection={
                  userProfile?.isManager
                    ? null
                    : {
                        ...rowSelection,
                      }
                }
                pagination={pagination}
                onChange={handleTableChange}
                loading={loading}
              />
            </div>
          </Col>
          {userProfile?.isManager ? (
            <Col xxl={{ span: 6, offset: 1 }} xl={{ span: 5, offset: 1 }}>
              <div className="artist-list-container">
                <div className="artistList">
                  <StanTypo
                    fontSize={20}
                    fontWeight={700}
                    content="Artist list"
                  />
                  <hr />
                  <div className="artistList-items">
                    {listArtist?.length > 0 ? (
                      listArtist?.map((artist) => {
                        return <ArtistItem artist={artist} key={artist.id} />;
                      })
                    ) : (
                      <Empty />
                    )}
                  </div>
                </div>
              </div>
            </Col>
          ) : null}
        </Row>
        <PostEditPopup
          onCancel={() =>
            dispatch({ type: SET_IS_OPEN, payload: { isOpen: false } })
          }
          onSuccess={fetchListSchedules}
        />
        {isOpenStreamModal && (
          <StreamModal
            closeModal={closeStreamModal}
            isOpenModal={isOpenStreamModal}
            type="edit"
            streamInfor={streamInfor}
            onUpdate={fetchListSchedules}
          />
        )}
        <AlertWarning
          onCancel={() => setAlertState({ ...alertState, isOpen: false })}
          isOpen={alertState.isOpen}
          onOK={alertState.onOK}
          title={alertState.title}
          buttonColor={alertState.buttonClor}
          btnText={alertState.btnText}
          isCancelButton={alertState.isCancelButton}
          desc={alertState.desc}
        />
      </div>
    </>
  );
}
