import { profileService } from "../../services/profileService";
import { GET_LIST_PROFILE } from "../types/ProfileType";

export const getListProfile = () => {
  return async (dispatch) => {
    try {
      const result = await profileService.getProfile();
      if (result && result.data.status === "success") {
        localStorage.setItem("UserProfile", JSON.stringify(result.data.data));
        dispatch({
          type: GET_LIST_PROFILE,
          payload: {
            listProfile: result.data.data,
            isLoading: false,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};
