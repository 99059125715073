import React from "react";
import { DatePicker } from "antd";

import "./date-time-input.css";
export default function StandDateInput({
  onChange,
  wrapperClass,
  disabled,
  placeholder,
  value,
  required,
  defaultValue,
  format,
  disabledDate
}) {
  return (
    <div className={`${wrapperClass} date-time-input-wrapper`}>
      <DatePicker
        size="large"
        placeholder={placeholder}
        onChange={(date, dateString) => onChange(date, dateString)}
        value={value?._isValid ? value : null}
        disabled={disabled}
        required={required}
        defaultValue={defaultValue}
        format={format}
        disabledDate={disabledDate}
      />
    </div>
  );
}
