import axios from "axios";
import { baseService } from "./baseService";
export class UploadService extends baseService {
  getPresignMedia = (type, file) => {
    let url = `api/v1/medias/presigned?type=${type}&`;
    if (file.type === "image/gif") {
      url += `extention=gif`;
    }
    return this.get(url);
  };

  uploadFile = async (type, file) => {
    let url = `api/v1/medias/presigned?type=${type}&`;
    if (file.type === "image/gif") {
      url += `extention=gif`;
    }
    const presignedData = await this.get(url);
    await uploadMedia(presignedData.data.data.url, file.originFileObj);
    return presignedData.data.data;
  };
  getVideoUpload = (fileSize) => {
    const url = `api/v1/users/vimeo-upload-link?fileSize=${fileSize}`;
    return this.get(url);
  };
}
export const uploadService = new UploadService();

export const uploadMedia = async (uploadURL, file) => {
  return await axios.put(uploadURL, file).then((res) => {
    return res;
  });
};
export const uploadVideo = async (uploadURL, file) => {
  const headers = {
    "Tus-Resumable": "1.0.0 ",
    "Upload-Offset": 0,
    "Content-Type": "application/offset+octet-stream",
    Accept: "application/vnd.vimeo.*+json;version=3.4",
  };
  return await axios
    .patch(uploadURL, file, {
      headers: headers,
    })
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      console.log("Errror =>", error);
    });
};
