import { baseService } from "./baseService";

export class StreamService extends baseService {
  getListStream = (filter, pageSize, page, sort = null) => {
    const filterOption = { ...filter };
    filterOption.fromDate =
      typeof filterOption.fromDate === "string" || !filterOption.fromDate
        ? filterOption.fromDate
        : filterOption.fromDate.format("DD/MM/YYYY");
    filterOption.toDate =
      typeof filterOption.toDate === "string" || !filterOption.toDate
        ? filterOption.toDate
        : filterOption.toDate.format("DD/MM/YYYY");
    let url = `api/v1/artist-web/stream?limit=${pageSize}&offset=${
      (page - 1) * pageSize
    }`;
    if (filterOption.fromDate && filterOption.fromDate !== "") {
      url += `&from=${filterOption.fromDate}`;
    }
    if (filterOption.toDate && filterOption.toDate !== "") {
      url += `&to=${filterOption.toDate}`;
    }
    if (filterOption.status !== null) {
      url += `&status=${filterOption.status}`;
    }
    if (filterOption.search && filterOption.search !== "") {
      url += `&keyword=${filterOption.search}`;
    }

    if (sort) {
      url += `&sort=CREATED_AT&order=${sort}`;
    }
    // if (data.timezone && data.timezone !== "") {
    url += `&timezone=${
      filterOption.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
    }`;
    // }
    return this.get(url);
  };
  getStreamById = (id) => {
    return this.get(
      `api/v1/artist-web/stream/${id}
      `
    );
  };
  updateStream = (id, data) => {
    return this.put(`api/v1/artist-web/stream/${id}`, data);
  };
  addStream = (data) => {
    return this.post(`api/v1/artist-web/stream/create`, data);
  };
  deleteStream = (data) => {
    return this.put(`api/v1/artist-web/stream/status`, data);
  };
  stopStream = (id) => {
    return this.put(`api/v1/artist-web/stream/stop`, { liveStreamId: id });
  };
}
export const streamService = new StreamService();
