import { baseService } from "./baseService";

export class ProfileService extends baseService {
  getProfile = () => {
    return this.get(
      `api/v1/artist-web/user/profile
      `
    );
  };
  updateProfile = (data) => {
    return this.put(`api/v1/artist-web/user/profile`, data);
  };
  getCountry =  ()=>{
   return this.get('api/v1/master-data/countries')
   

  }

}
export const profileService = new ProfileService();
