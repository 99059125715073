import "./StanIconButton.css";

export default function StanIconButton(props){
    const {icon} = props;
    return (
        <>
            <div className="StanIconButton">
                {icon}
            </div>
        </>
    )
}