import { Dropdown } from "antd";
import AlertWarning from "component/AlertWarning";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../../../App";
import {
  CheckIcon,
  ChevronDown,
  EngFlag,
  KorFlag,
  PowerIcon,
  SwitchIcon,
  ThaiFlag,
  TranslateIcon,
  UserIcon,
  VietFlag,
} from "../../../../common/commonSvg";
import { STAN_MENUS } from "../../../../common/Menus";
import StanTypo from "../../../../component/StanTypo/StanTypo";
import { getListProfile } from "../../../../redux/action/ProfileAction";
import { logOut } from "../../../../redux/action/UserAction";
import "./Header.css";

export default function Header(props) {
  const { t, i18n } = useTranslation(["common"]);
  const userProfile = JSON.parse(localStorage.getItem("UserProfile"));
  const managerToken = localStorage.getItem("ManagerToken");
  const { listProfile } = useSelector((state) => state.ProfileReducer);
  const dispatch = useDispatch();
  const [alertState, setAlertState] = useState({});

  useEffect(() => {
    dispatch(getListProfile());
  }, []);

  function backToMainAccount() {
    if (managerToken && managerToken !== "") {
      Cookies.set("TokenAW", managerToken);
      history.push("/dashboard");
      window.location.reload();
    }
  }

  const Menus = STAN_MENUS;

  const items = [
    {
      key: "1",
      label: (
        <div className="d-flex flex-column">
          <StanTypo
            fontSize={18}
            fontWeight={600}
            content={listProfile?.name}
            className="menu-profile-name"
          />
          <StanTypo
            fontSize={14}
            fontWeight={500}
            content={listProfile?.isManager ? "Manager" : "Artist"}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div onClick={() => history.push("/profile")}>
          <StanTypo fontSize={16} content="Profile" />
        </div>
      ),
      icon: <UserIcon />,
    },
    {
      key: "3",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="#"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <StanTypo fontSize={16} content="Language" />
        </a>
      ),
      children: [
        {
          key: "3-1",
          label: "Vietnam",
          icon: <VietFlag />,
        },
        {
          type: "divider",
        },
        {
          key: "3-2",
          label: "Korea",
          icon: <KorFlag />,
        },
        {
          type: "divider",
        },
        {
          key: "3-3",
          label: "Thailand",
          icon: <ThaiFlag />,
        },
        {
          type: "divider",
        },
        {
          key: "3-4",
          label: (
            <div className="item-language">
              {" "}
              English <CheckIcon />{" "}
            </div>
          ),
          icon: <EngFlag />,
        },
      ],
      icon: <TranslateIcon />,
    },
    !userProfile?.isManager &&
    managerToken &&
    managerToken !== Cookies.get("TokenAW")
      ? {
          key: "4",
          label: (
            <div onClick={() => backToMainAccount()}>
              <StanTypo fontSize={16} content="Back to main account" />
            </div>
          ),
          icon: <SwitchIcon />,
        }
      : null,
    {
      type: "divider",
    },
    {
      key: "logOut",
      label: (
        <div
          onClick={() => {
            setAlertState({
              isOpen: true,
              buttonClor: "#49D19A",
              btnText: "Log out",
              title: "Stan",
              desc: "Are you sure you want to log out? You can always come back by signing in.",
              isCancelButton: true,
              onOK: () => dispatch(logOut()),
            });
          }}
          className="Header-logout"
        >
          <div>
            <StanTypo fontSize={16} content="Logout" />
          </div>
        </div>
      ),
      icon: <PowerIcon />,
    },
  ];
  return (
    <>
      <div className="Header">
        <div className="Header-title">
          {Menus.map((menu, index) => {
            if (window.location.pathname.includes(menu.key)) {
              return <h1 key={index}>{menu.title}</h1>;
            }
            return null;
          })}
        </div>
        <div className="Header-profile">
          <div className="avatar">
            <img alt="" src={listProfile?.avatar} />
          </div>
          <Dropdown
            menu={{
              items,
            }}
            placement="bottomRight"
            trigger={["click"]}
            className="Header-dropdown"
          >
            <a
              href="#"
              onClick={(e) => e.preventDefault()}
              className=" header-infor d-flex  align-items-center gap-5"
            >
              <div className="d-flex flex-column gap-0">
                <StanTypo
                  className="proifle-name"
                  fontSize={18}
                  fontWeight={600}
                  content={listProfile?.name}
                />
                <StanTypo
                  fontSize={14}
                  fontWeight={500}
                  content={listProfile?.isManager ? "Manager" : "Artist"}
                />
              </div>
              <div className="">
                <a href="#" onClick={(e) => e.preventDefault()}>
                  <ChevronDown />
                </a>
              </div>
            </a>
          </Dropdown>
        </div>
      </div>
      <AlertWarning
        onCancel={() => setAlertState({ ...alertState, isOpen: false })}
        isOpen={alertState.isOpen}
        onOK={alertState.onOK}
        title={alertState.title}
        buttonColor={alertState.buttonClor}
        btnText={alertState.btnText}
        isCancelButton={alertState.isCancelButton}
        desc={alertState.desc}
      />
    </>
  );
}
