import { Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactImageUploading from "react-images-uploading";
import { openNotificationWithIcon } from "../../../../App";
import { IMAGE_ACCEPT_TYPE, IMAGE_TYPE, RULES } from "../../../../common/Constant";
import StanTypo from "../../../../component/StanTypo/StanTypo";
import {
  uploadMedia,
  uploadService,
} from "../../../../services/uploadMediaService";

import "./ProfileHeader.css";
import ProfileHeaderButton from "./ProfileHeaderButton";
const ProfileHeader = (props) => {
  const {
    isLoading,
    setFileAvatar,
    setFormValue,
    formValue,
    form,
    isEdit,
    setImages,
    images,
    handleEditProfile,
    handleCancelEdit,
  } = props;
  const { t } = useTranslation(["profile"]);
  const [imageList, setImageList] = useState();
  const rules = RULES();
  const onChange = (imageList) => {
    const imageFile = imageList[0].file;
    const isMoreThan10MB = imageFile.size / 1024 / 1024 > 10;
    if (isMoreThan10MB) {
      openNotificationWithIcon("error", t("VALIDATE.MAX_10MB"));
    } else if (IMAGE_TYPE.includes(imageFile.type)) {
      setImages(imageList);
      setImageList(imageList);
    } else openNotificationWithIcon("error", t("VALIDATE.IMAGE"));
  };
  const handleEdit = async () => {
    if (imageList) {
      const imageFile = imageList[0].file;
      const getPresign = await uploadService.getPresignMedia(
        imageFile.type,
        imageFile
      );
      const resultUpload = await uploadMedia(
        getPresign?.data?.data.url,
        imageFile
      );
      if (resultUpload.statusText === "OK") {
        formValue.avatar = imageList[0].data_url;

        setFileAvatar({
          fileName: imageFile.name,
          filePath: getPresign.data.data.path,
        });
      }
    }

    form.submit();
  };
  return (
    <div className="header-profile">
      <div className="header-left">
        <ReactImageUploading
          multiple
          value={images}
          onChange={onChange}
          maxNumber={30}
          dataURLKey="data_url"
          onError={()=>{openNotificationWithIcon("error", t("VALIDATE.IMAGE"))}}
          acceptType={IMAGE_ACCEPT_TYPE}
        >
          {({
            onImageUpload,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            <div>
              <div
                className="image-item-profile"
                onClick={isEdit ? () => onImageUpdate(0) : () => {}}
              >
                <img width={110} src={images[0]["data_url"]} alt="avatar" />
                {isEdit && (
                  <div className="image-hover">
                    <img src="/svg/add-image.svg" />
                  </div>
                )}
              </div>
            </div>
          )}
        </ReactImageUploading>
        <div className="d-flex flex-column ml-3 profile-header-infor">
          <Form.Item rules={rules.name} name="name">
            <TextArea
              disabled={!isEdit}
              maxLength={50}
              placeholder={t("PLACEHOLDER.NAME")}
              autoSize
            />
          </Form.Item>

          <StanTypo
            fontSize={14}
            fontWeight={500}
            content={
              formValue?.isManager
                ? t("TYPE_ACCOUNT.MANAGER")
                : t("TYPE_ACCOUNT.ARTIST")
            }
          />
        </div>
      </div>
      <ProfileHeaderButton
        isEdit={isEdit}
        isLoading={isLoading}
        handleCancelEdit={handleCancelEdit}
        handleEdit={handleEdit}
        handleEditProfile={handleEditProfile}
        formValue={formValue}
        setImages={setImages}
        setImageList={setImageList}
      />
    </div>
  );
};

export default ProfileHeader;
