import { useTranslation } from "react-i18next";
import { CheckDomain, checkDomain, CheckPhoneNum } from "./common";
export const breakPoints = {
  xl: "1440px",
  lg: "1024px",
  md: "768px",
  sm: "425px",
  xs: "375px",
  xxs: "320px",
};

export const STAN_STATUS = {
  SUCCESS: "SUCCESS",
  WARNING: "WARNING",
  DANGER: "DANGER",
};

export const PERMISSION = {
  APPROVED: "APPROVED",
  DENIED: "DENIED",
  REPORTED: "REPORTED",
  EXPIRED: "EXPIRED",
};

export const SOCIAL_MEDIA = {
  FACEBOOK: "facebook",
  GOOGLE: "google",
  APPLE: "apple",
  WECHAT: "wechat",
  NAVER: "naver",
  KAKAO: "kakao",
  LINE: "line",
};

export const DASHBOARD_STATUS = [
  { label: "All", value: null },
  { label: "Blocked", value: 0 },
  { label: "Active", value: 1 },
  { label: "On going", value: 5 },
];

export const DASHBOARD_TYPE = [
  { label: "All", value: null },
  { label: "Post", value: 1 },
  { label: "Stream", value: 4 },
];

export const DASHBOARD_ITEMS_VALUE = {
  POST: 1,
  STREAM: 4,
};

export const STAN_ARTIST_ROLE = {
  ARTIST: 2,
  FAN: 1,
};

export const IMAGE_TYPE = ["image/jpg", "image/png", "image/jpeg"];
export const IMAGE_TYPE_POST = [
  "image/jpg",
  "image/png",
  "image/jpeg",
  "image/gif",
  "video/mp4",
];
export const IMAGE_ACCEPT_TYPE = ["jpg", "png", "jpeg"];
export const DATE_FORMAT = "DD/MM/YYYY";
export const TIME_FORMAT = "HH:mm:ss";
export const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss";
export const PAGE_SIZE = 10;
export const RULES = () => {
  const { t } = useTranslation(["common"]);
  return {
    email: [
      {
        type: "email",
        message: t("VALIDATE.ENTER_VALID_EMAIL"),
      },
      {
        max: 255,
        message: t("VALIDATE.MAX_255"),
      },
    ],
    location: [
      {
        max: 200,
        message: t("VALIDATE.MAX_200"),
      },
    ],
    name: [
      {
        validator: (_, value) => {
          const checkNameLenght = value.replace(/\s/g, "").length;
          if (checkNameLenght === 0) {
            return Promise.reject(t("VALIDATE.REQUIRED_FIELD"));
          }
          return Promise.resolve();
        },
      },
    ],
    phone: [
      { required: false },

      {
        validator: (_, value) => {
          const checkNumValue = CheckPhoneNum(value);

          if (!checkNumValue && value.length > 0) {
            return Promise.reject(t("VALIDATE.ENTER_VALID_NUMBER"));
          }
          return Promise.resolve();
        },
      },
    ],
    domain: [
      {
        validator: (_, value) => {
          const checkNumValue = CheckDomain(value);
          if (checkNumValue && value.length > 0) {
            return Promise.reject(t("VALIDATE.ENTER_VALID_URL"));
          }
          return Promise.resolve();
        },
      },
    ],
  };
};

export const FILE_TYPES = {
  VIDEO: "video",
  AUDIO: "audio",
  IMAGE: "image",
};
