import { useState } from "react";
import { streamService } from "../../services/streamService";
import { STANDARD_STATUS } from "../../util/common-types";
import { openSuccessToast } from "../../atomics/StanToast";
import { PAGE_SIZE } from "common/Constant";
import * as _ from 'lodash';
export default function useStreamManagement() {
  const [listStream, setListStream] = useState([]);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("DESC");
  const pageSize=PAGE_SIZE;
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filterOption, setFilterOption] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    type: "",
    status: null,
  });
  const fetchListStream = async () => {
    let res = await streamService.getListStream(filterOption, pageSize, page, sort);
    setLoading(false)
    setListStream(res.data?.data?.items);
    setTotalItems(res.data?.data?.totalItems);
  };
  const deleteStream = async (idList, page = 0) => {
    let res = await streamService.deleteStream({
      liveStreamIds: idList,
      status: STANDARD_STATUS.deleted
    })
    if(res.data.status === "success") {
      setPage(1);
      fetchListStream();
      openSuccessToast("Success!", "Stopped successfully!")
    } else {
    }
  };

  const mapField = {
    title: 'TITLE',
    ownerName: 'OWNER',
    createdAt: 'CREATED_AT',
  }
  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination?.current);
    // setSort({col: mapField[sorter.field], dir: sorter.order === "ascend" ? "ASC" : "DESC"});
    fetchListStream()
  };
  const onSort = () => {
    if(sort === "DESC")  setSort("ASC");
    else setSort("DESC");
  }
  return {
    listStream,
    page,
    pageSize,
    filterOption,
    setFilterOption,
    fetchListStream,
    totalItems,
    handleTableChange,
    deleteStream,
    loading,
    setPage,
    setLoading,
    onSort, 
    sort
  };
}
