import React, { useState } from 'react'
import "./ActionFormatter.css";
import { CloseCircleFilled } from "@ant-design/icons";
import AlertWarning from "component/AlertWarning";

export default function ActionStop({onClick}) {
	const [isShowModal, setIsShowModal] = useState(false);
    const handleCancel = () => setIsShowModal(false);
    const handleOpen = () => setIsShowModal(true);
  return (
  	<>
	    <div className='action-stop' onClick={handleOpen}>
	      <CloseCircleFilled />&nbsp;
	      Stop
	    </div>
	    <AlertWarning
		    onOK={() => {
		      onClick();
		    }}
		    onCancel={handleCancel}
		    danger={true}
		    type={"primary"}
		    desc={"Are you sure you want to stop this stream?"}
		    isCancelButton={true}
		    isOpen={isShowModal}
		    btnText={"Stop"}
		  />
	</>
  )
}
