import StanTypo from "component/StanTypo/StanTypo";
import React from "react";
import { STANDARD_STATUS } from "../../util/common-types";
import "./index.css";
export default function StanStatusBadge({ className, status, type }) {
  const text = (() => {
    switch (status) {
      case STANDARD_STATUS.active:
        return "Active";
      case STANDARD_STATUS.blocked:
        return "Blocked";
      case STANDARD_STATUS.onGoing:
        return "On-going";
      case STANDARD_STATUS.scheduled:
        return "On-going";
      default:
        return "";
    }
  })();
  return (
    <>
      {type !== "detail" ? (
        <div
          className={`stand-status-badge-wrapper ${className} ${text}-status-badge`}
        >
          {text}
        </div>
      ) : (
        <StanTypo
          fontSize={14}
          fontWeight={600}
          color={
            text === "Active"
              ? "#0C6942"
              : text === "Blocked"
              ? "#751A1A"
              : "#005970"
          }
          content={`#${text}`}
        />
      )}
    </>
  );
}
