import {
  SET_IS_OPEN,
  SET_IS_EDIT,
  SET_ACTION_TYPE,
  SET_POST_STATE,
  SET_STREAM_STATE,
  SET_LIST_IMGS,
  SET_LIST_USER_TAGGED,
} from "../types/PopupAddEditType";
import moment from "moment";
import { ActionType } from "../types/PopupAddEditType";
import { TIME_FORMAT } from "common/Constant";
export const initialState = {
  isOpen: false,
  isEdit: false,
  actionType: ActionType.addPost,
  videoSrc: "",
  isEdit: false,
  postState: {
    id: null,
    isPublic: false,
    date: "",
    time: "",
    listImgs: [],
    title: "",
    description: "",
    displayWidth: 0,
    displayHeight: 0,
    tags: [],
    taggedUsers: [],
    taggedUserIds: [],
    isSquare: false,
    startTime: "",
  },
  streamState: {
    date: "",
    time: "",
    videoSrc: "",
    isPublic: false,
    title: "",
    description: "",
    displayWidth: 0,
    displayHeight: 0,
    tags: [],
    filePaths: [
      {
        type: "video",
        path: "string",
        width: 400,
        height: 400,
      },
    ],
  },
  // " post" | "livestream"
};

export const PopupAddEditReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_OPEN:
      return {
        ...state,
        isOpen: action.payload.isOpen,
      };
    case SET_IS_EDIT:
      return {
        ...state,
        isEdit: action.payload.isEdit,
      };
    case SET_ACTION_TYPE:
      return {
        ...state,
        actionType: action.payload.actionType,
      };
    case SET_POST_STATE:
      if (action.payload.postState.listImgs.length <= 10) {
        return {
          ...state,
          postState: { ...action.payload.postState },
        };
      }
    case SET_STREAM_STATE:
      return {
        ...state,
        streamState: { ...action.payload.streamState },
      };
    case SET_LIST_IMGS:
      return {
        ...state,
        postState: { ...state.postState, listImgs: action.payload?.listImgs },
      };
    case SET_LIST_USER_TAGGED:
      return {
        ...state,
        postState: {
          ...state.postState,
          taggedUsers: action.payload.taggedUsers,
        },
      };
    default:
      return state;
  }
};
