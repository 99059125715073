import {
  PERMISSION_APPROVED,
  PERMISSION_DENIED,
  PERMISSION_EXPIRED,
  PERMISSION_PENDING,
  PERMISSION_SENT,
  SAVE_LINE_RESPONSE,
  SAVE_NAVER_RESPONSE,
} from "../types/UserType";

const initialState = {
  linePayload: {},
  naverPayload: {},
  permissionStatus: PERMISSION_PENDING,
};

export const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LINE_RESPONSE:
      return {
        ...state,
        linePayload: action.linePayload,
      };
    case SAVE_NAVER_RESPONSE:
      return {
        ...state,
        naverPayload: action.naverPayload,
      };
    case PERMISSION_PENDING:
      return {
        ...state,
        permissionStatus: PERMISSION_PENDING,
      };
    case PERMISSION_SENT:
      return {
        ...state,
        permissionStatus: PERMISSION_SENT,
      };
    case PERMISSION_APPROVED:
      return {
        ...state,
        permissionStatus: PERMISSION_APPROVED,
      };
    case PERMISSION_DENIED:
      return {
        ...state,
        permissionStatus: PERMISSION_DENIED,
      };
    case PERMISSION_EXPIRED:
      return {
        ...state,
        permissionStatus: PERMISSION_EXPIRED,
      };
    default:
      return state;
  }
};
