import { GET_LIST_PROFILE } from "../types/ProfileType";

const initialState = {
  listProfile: {},
};

export const ProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_PROFILE:
      return {
        ...state,
        listProfile: action.payload.listProfile,
      };
    default:
      return state;
  }
};
