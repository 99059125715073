import { baseService } from "./baseService";

export class DashboardService extends baseService {
  getListSchedule = async (filter, page, pageSize) => {
    const filterOption = { ...filter };
    filterOption.fromDate =
      typeof filterOption.fromDate === "string" || !filterOption.fromDate
        ? filterOption.fromDate
        : filterOption.fromDate.format("DD/MM/YYYY");
    filterOption.toDate =
      typeof filterOption.toDate === "string" || !filterOption.toDate
        ? filterOption.toDate
        : filterOption.toDate.format("DD/MM/YYYY");
    let url = `api/v1/artist-web/posts/dashboard?limit=${pageSize}&offset=${
      (page - 1) * pageSize
    }`;
    if (filterOption.fromDate && filterOption.fromDate !== "") {
      url += `&from=${filterOption.fromDate}`;
    }
    if (filterOption.toDate && filterOption.toDate !== "") {
      url += `&to=${filterOption.toDate}`;
    }
    if (filterOption.status || filterOption.status === 0) {
      url += `&status=${filterOption.status}`;
    }
    if (filterOption.type) {
      url += `&type=${filterOption.type}`;
    }
    if (filterOption.search && filterOption.search !== "") {
      url += `&keyword=${filterOption.search}`;
    }
    if (filterOption.sort && filterOption.order) {
      url += `&sort=${filterOption.sort}&order=${filterOption.order}`;
    }
    // if (data.timezone && data.timezone !== "") {
    url += `&timezone=${
      filterOption.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
    }`;
    // }
    return this.get(url);
  };

  getListArtist = (page, pageSize) => {
    let url = `api/v1/artist-web/delegate-manager?limit=${pageSize}&offset=${
      (page - 1) * pageSize
    }`;
    return this.get(url);
  };

  removeAccount = (delegateId) => {
    let url = `api/v1/artist-web/delegate-manager/${delegateId}`;
    return this.delete(url);
  };
}

export const dashboardService = new DashboardService();
