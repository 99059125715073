import { postManagementService } from "../../services/postManagementService";
import { GET_DETAIL_POST, GET_LIST_POST, GET_REPORT_HISTORY, VIEW_DETAIL_POST } from "../types/PostManagementType";

export const getListPost = (data, pageSize, page) => {
  return async (dispatch) => {
    try {
      const result = await postManagementService.getListPost(
        data,
        pageSize,
        page
      );
      if (result && result.data.status === "success") {
        dispatch({
          type: GET_LIST_POST,
          payload: {
            listPost: result.data.data.items,
            totalPost: result.data.data.totalItems,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getDetailPost = (postId) => {
  return async (dispatch) => {
    try {
      const result = await postManagementService.getDetailPost(postId);
      if (result && result.data.status === "success") {
        dispatch({
          type: VIEW_DETAIL_POST,
          payload: { postDetail: result.data.data, postDetailMedia: result.data.data.media },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getReportHistory = (postId, pageSize, page) => {
  return async (dispatch) => {
    try {
      const result = await postManagementService.getReportHistory(postId, pageSize, page);
      if (result && result.data.status === "success") {
        dispatch({
          type: GET_REPORT_HISTORY,
          payload: {
            reportHistory: result.data.data.items,
            totalReportHistory: result.data.data.totalItems
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const deletePost = (data) => {
  return async (dispatch) => {
    try {
      const result = await postManagementService.deletePost(data);
      // if (result && result.data.status === "success") {
      //   dispatch({
      //     type: GET_REPORT_HISTORY,
      //     payload: {
      //       reportHistory: result.data.data.items,
      //       totalReportHistory: result.data.data.totalItems,
      //     },
      //   });
      // }
    } catch (error) {
      console.log(error);
    }
  };
};