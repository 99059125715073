import { Col, Row } from "antd";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import StanButton from "../../component/StanButton/StanButton";
import StanFilter from "../../component/StanFilter/StanFilter";
import StanTypo from "../../component/StanTypo/StanTypo";
import StreamModal from "./StreamComponent/StreamModal/StreamModal";
import StreamTable from "./StreamComponent/StreamTable/StreamTable";
import useStreamManagement from "./useStreamManagement";
import "./stream.css";
export default function PostManagement() {
  const {
    listStream,
    page,
    pageSize,
    filterOption,
    setFilterOption,
    fetchListStream,
    totalItems,
    handleTableChange,
    deleteStream,
    loading,
    setPage,
    setLoading,
    onSort,
    sort
  } = useStreamManagement();
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const pagination = {
    current: page,
    pageSize,
    total: totalItems,
  };
  useEffect(() => {
    fetchListStream();
  }, [
    page,
    filterOption.fromDate,
    filterOption.toDate,
    filterOption.status,
    filterOption.search,
    sort
  ]);
  const openAddModal = () => {
    setIsOpenAddModal(true);
  };
  const closeAddModal = () => {
    setIsOpenAddModal(false);
  };

  return (
    <>
      <div style={{ minHeight: "100vh" }}>
        <span>Post Management</span>
        <Row justify={"end"}>
          <Col>
            <StanButton
              onClick={() => {
                openAddModal();
              }}
              type="primary"
            >
              <StanTypo color="white" content="Add new livestream" />
            </StanButton>
          </Col>
        </Row>
        <StanFilter
          setFilterOption={setFilterOption}
          filterOption={filterOption}
          optionsStatus={[
            { label: "All", value: null },
            { label: "Blocked", value: 0 },
            { label: "Active", value: 1 },
            { label: "On Going", value: 5 },
          ]}
          searchPlaceholder="Search by Title, Creator"
        />
        <div className="PostManagement-tableContainer">
          <StreamTable
            rowKey={(row) => row.id}
            dataSource={listStream}
            pagination={pagination}
            handleTableChange={handleTableChange}
            loading={loading}
            deleteStream={deleteStream}
            onUpdateDateSource={fetchListStream}
            onEditRow={fetchListStream}
            setLoading={setLoading}
            onSort={onSort}
          />
        </div>
        {isOpenAddModal && (
          <StreamModal
            closeModal={closeAddModal}
            isOpenModal={isOpenAddModal}
            type="add"
            // getListStream={()=>getListStream()}
            onUpdate={() => {
              setPage(1);
              fetchListStream();
            }}
          />
        )}
      </div>
    </>
  );
}
