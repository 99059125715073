import { LoadingOutlined } from "@ant-design/icons";
import { Upload, Modal } from "antd";
import "./index.css";
import { useState } from "react";
import { getBase64 } from "common/common";
export default function StanImageUpload({
  handleChange,
  loading,
  listImgs,
  accept,
  maxCount,
  disabled,
}) {
  const beforeUpload = (file, fileList) => {
    return false;
  };
  const [previewImage, setPreviewImage] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");
  const handlePreview = async (file) => {
    if (file.type === "video/mp4" || file.thumbUrl) {
      file.preview = file.thumbUrl;
    } else if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file?.url || file?.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file?.url?.substring(file?.url?.lastIndexOf("/") + 1)
    );
  };
  const handleCancel = () => setPreviewOpen(false);
  return (
    <div className="stand-upload-container">
      <Upload
        multiple={true}
        className="mt-3 mb-3"
        accept={accept}
        action={false}
        listType="picture-card"
        fileList={listImgs}
        maxCount={maxCount}
        onChange={handleChange}
        beforeUpload={beforeUpload}
        type="file"
        showUploadList
        handlePreivew={() => {}}
        onPreview={handlePreview}
        // originNode={ImageItem}
        disabled={disabled}
      >
        <div className="upload-container">
          <div>
            {loading ? <LoadingOutlined /> : <img src="/svg/add-image.svg" />}
          </div>
          <div>Upload</div>
        </div>
      </Upload>
      <Modal
        open={previewOpen}
        title={<p>{previewTitle}</p>}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" className="post-preview-image" src={previewImage} />
      </Modal>
      {/* <div className="post-images-group">
        {listImgs?.map(img => <ImageItem src={img.thumbUrl}/>)}
      </div> */}
    </div>
  );
}
