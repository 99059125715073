import { Skeleton } from "antd";

export default function StanTypo(props) {
  const { className, fontSize, fontWeight, color, content, onLoad, loading, customStyle } =
    props;

  const style = {
    ...customStyle,
    fontSize: fontSize + "px",
    fontWeight: fontWeight,
    color: color,
  };

  return (
    <>
      {!onLoad && loading ? (
        <Skeleton.Input active={true} />
      ) : (
        <span className={className} style={style}>
          {content}
        </span>
      )}
    </>
  );
}
