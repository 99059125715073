import { Button } from "antd";
import axios from "axios";
import SocialButton from "component/SocialButton/SocialButton";
import StanButton from "component/StanButton/StanButton";
import { useEffect } from "react";
import AppleLogin from "react-apple-login";
import GoogleLogin from "react-google-login";
import { useTranslation } from "react-i18next";
import KakaoLogin from "react-kakao-login";
import { useDispatch, useSelector } from "react-redux";
import {
  PERMISSION_DENIED,
  PERMISSION_EXPIRED,
  PERMISSION_PENDING,
  PERMISSION_SENT,
} from "redux/types/UserType";
import {
  AppleIcon,
  GoogleIcon,
  InfoCircleIcon,
  KakaoTalkIcon,
  LineIcon,
  NaverIcon,
  StanLogo,
  WarningIcon,
} from "../../common/commonSvg";
import { SOCIAL_MEDIA } from "../../common/Constant";
import StanTypo from "../../component/StanTypo/StanTypo";
import { handleSocketLogin } from "../../redux/action/UserAction";
import {
  APPLE_CLIENT_ID,
  FACEBOOK_APP_ID,
  GOOGLE_CLIENT_ID,
  KAKAO_KEY,
  LINE_KEY,
  LINE_SECRET,
  NAVER_KEY,
  NAVER_SECRET,
  REDIRECT_URL,
} from "../../util/settings/config";
import { generateRandomString } from "../../util/settings/utils";
import "./Login.css";
export default function Login() {
  const { t } = useTranslation(["login"]);
  const { linePayload, naverPayload, permissionStatus } = useSelector(
    (state) => state.UserReducer
  );
  const dispatch = useDispatch();

  // HANDLE

  useEffect(() => {
    // HANDLE LINE LOGIN
    if (linePayload.hasOwnProperty("code")) {
      const reqConfig = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      axios
        .post(
          "https://api.line.me/oauth2/v2.1/token",
          new URLSearchParams({
            grant_type: "authorization_code",
            code: linePayload.code,
            redirect_uri: REDIRECT_URL,
            client_id: LINE_KEY,
            client_secret: LINE_SECRET,
          }),
          reqConfig
        )
        .then((res) => {
          if (res && res.data) {
            dispatch(
              handleSocketLogin(res.data.access_token, SOCIAL_MEDIA.LINE)
            );
          }
        });
    }
  }, [linePayload]);

  useEffect(() => {
    // HANDLE NAVER LOGIN
    if (naverPayload.hasOwnProperty("code")) {
      const reqConfig = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      axios
        .post("https://nid.naver.com/oauth2.0/token", "", {
          data: {
            grant_type: "authorization_code",
            client_id: NAVER_KEY,
            client_secret: NAVER_SECRET,
            code: naverPayload.code,
            state: "naver",
          },
          headers: { "Access-Control-Allow-Origin": "*" },
        })
        .then((res) => {
          console.log("RES =>", res);
          if (res && res.data) {
            dispatch(
              handleSocketLogin(res.data.access_token, SOCIAL_MEDIA.NAVER)
            );
          }
        });
    }
  }, [naverPayload]);

  const responseKakaoTalk = async (response) => {
    console.log("RESPONSE KAKAO", response);
    localStorage.setItem("TokenKakao", response.response.access_token); // Save token Kakao into Local Storage
    if (response && response?.response?.access_token) {
      dispatch(
        handleSocketLogin(response.response.access_token, SOCIAL_MEDIA.KAKAO)
      );
    }
  };

  const responseFacebook = (response) => {
    console.log("RESPONSE FACEBOOK", response);
    localStorage.setItem("TokenFacebook", response._token?.accessToken); // Save token facebook into Local Storage
    if (response && response._token?.accessToken) {
      dispatch(
        handleSocketLogin(response._token?.accessToken, SOCIAL_MEDIA.FACEBOOK)
      );
    }
  };

  const responseGoogle = async (response) => {
    // localStorage.setItem("TokenGoogle", response.tokenId); // Save token Google into Local Storage
    if (response && response.tokenId) {
      dispatch(handleSocketLogin(response.tokenId, SOCIAL_MEDIA.GOOGLE));
    }
  };

  const responseApple = async (response) => {
    console.log("RESPONSE APPLE", response);
    if (response && response.error) {
      // openNotificationWithIcon("error", "Error", response.error.error);
    } else if (response && !response.error && response.authorization) {
      localStorage.setItem("TokenApple", response.authorization.id_token); // Save token Apple into Local Storage
      dispatch(
        handleSocketLogin(response.authorization.id_token, SOCIAL_MEDIA.APPLE)
      );
    }
  };

  function handleLoginLine() {
    const clientId = LINE_KEY;
    const redirect_url = encodeURIComponent(REDIRECT_URL);
    const state = generateRandomString(5);
    const scope = encodeURIComponent("profile openid email");
    const url =
      "https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=" +
      clientId +
      "&redirect_uri=" +
      redirect_url +
      "&state=" +
      state +
      "&scope=" +
      scope;
    window.location.assign(url);
  }

  function handleLoginNaver() {
    const clientId = NAVER_KEY;
    const redirect_url = encodeURIComponent(REDIRECT_URL);
    const url =
      "https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=" +
      clientId +
      "&state=naver&redirect_uri=" +
      redirect_url;
    window.location.assign(url);
  }

  const onFailure = (response) => {
    console.log(response);
  };

  // UI RENDER
  const SignInKakaoTalk = () => {
    return (
      <KakaoLogin
        token={KAKAO_KEY}
        onSuccess={responseKakaoTalk}
        onFail={onFailure}
        render={(renderProps) => {
          return (
            <Button
              className="btn-kakaoTalkLogin"
              onClick={renderProps.onClick}
            >
              <KakaoTalkIcon />
              <StanTypo
                fontSize={14}
                fontWeight={500}
                content="Sign in with KakaoTalk"
              />
            </Button>
          );
        }}
      />
    );
  };

  const SignInFacebook = () => {
    return (
      // <FacebookLogin
      //   autoLoad={false}
      //   appId={FACEBOOK_APP_ID}
      //   callback={responseFacebook}
      //   fields="name,email,picture"
      //   scope="public_profile"
      //   render={(renderProps) => (
      //     <Button className="btn-facebookLogin" onClick={renderProps.onClick}>
      //       <FacebookIcon />
      //       <StanTypo
      //         fontSize={14}
      //         fontWeight={500}
      //         color="#FFFFFF"
      //         content="Sign in with Facebook"
      //       />
      //     </Button>
      //   )}
      // />
      <SocialButton
        provider="facebook"
        appId={FACEBOOK_APP_ID}
        autoLogin={false}
        onLoginSuccess={responseFacebook}
        onLoginFailure={onFailure}
      />
    );
  };

  const SignInGoogle = () => {
    return (
      <GoogleLogin
        render={(renderProps) => {
          return (
            <Button
              className="btn-googleLogin"
              onClick={() => {
                renderProps.onClick();
              }}
            >
              <GoogleIcon />
              <StanTypo
                fontSize={14}
                fontWeight={500}
                content="Sign in with Google"
              />
            </Button>
          );
        }}
        autoLoad={false}
        isSignedIn={false}
        clientId={GOOGLE_CLIENT_ID}
        onSuccess={responseGoogle}
        onFailure={onFailure}
        cookiePolicy="single_host_origin"
      />
    );
  };

  const SignInApple = () => {
    return (
      <AppleLogin
        clientId={APPLE_CLIENT_ID}
        redirectURI={REDIRECT_URL}
        responseType={"code"}
        responseMode={"query"}
        usePopup={true}
        render={(renderProps) => {
          return (
            <Button className="btn-appleLogin" onClick={renderProps.onClick}>
              <AppleIcon />
              <StanTypo
                fontSize={14}
                fontWeight={500}
                color="#FFFFFF"
                content="Sign in with Apple"
              />
            </Button>
          );
        }}
        callback={responseApple}
      />
    );
  };

  const SignInNaver = () => {
    return (
      <Button className="btn-naverLogin" onClick={() => handleLoginNaver()}>
        <NaverIcon />
        <StanTypo
          fontSize={14}
          fontWeight={500}
          color="#FFFFFF"
          content="Sign in with Naver"
        />
      </Button>
    );
  };

  const SignInLine = () => {
    return (
      <Button className="btn-naverLogin" onClick={() => handleLoginLine()}>
        <LineIcon />
        <StanTypo
          fontSize={14}
          fontWeight={500}
          color="#FFFFFF"
          content="Sign in with Line"
        />
      </Button>
    );
  };

  const RequestLoginHasSent = () => {
    return (
      <div className="d-flex flex-column">
        <InfoCircleIcon />
        <StanTypo
          fontSize={18}
          fontWeight={500}
          content={t("TEXT.REQUEST_SENT")}
        />
      </div>
    );
  };

  const RequestLoginDenied = () => {
    return (
      <div className="d-flex flex-column">
        <WarningIcon />
        <StanTypo
          fontSize={18}
          fontWeight={500}
          content={t("TEXT.REQUEST_DENIED")}
        />
        <StanButton
          className="mt-3"
          style={{ backgroundColor: "#49D19A" }}
          onClick={() => dispatch({ type: PERMISSION_PENDING })}
        >
          <StanTypo
            fontSize={16}
            fontWeight={700}
            color="#FFFFFF"
            content="Try again"
          />
        </StanButton>
      </div>
    );
  };

  const RequestLoginExpired = () => {
    return (
      <div className="d-flex flex-column">
        <WarningIcon />
        <StanTypo
          fontSize={18}
          fontWeight={500}
          content={t("TEXT.REQUEST_EXPIRED")}
        />
        <StanButton
          className="mt-3"
          style={{ backgroundColor: "#49D19A" }}
          onClick={() => dispatch({ type: PERMISSION_PENDING })}
        >
          <StanTypo
            fontSize={16}
            fontWeight={700}
            color="#FFFFFF"
            content="Try again"
          />
        </StanButton>
      </div>
    );
  };

  const RenderUI = () => {
    switch (permissionStatus) {
      case PERMISSION_SENT:
        return <RequestLoginHasSent />;
      case PERMISSION_DENIED:
        return <RequestLoginDenied />;
      case PERMISSION_EXPIRED:
        return <RequestLoginExpired />;
      default:
        return (
          <>
            <SignInKakaoTalk />
            <SignInFacebook />
            <SignInGoogle />
            <SignInApple />
            <SignInNaver />
            <SignInLine />
          </>
        );
    }
  };

  return (
    <>
      <div className="LoginPage" style={{ minHeight: "100vh" }}>
        <div className="Login-platforms">
          <div id="stanLogo" className="mb-5">
            <StanLogo />
          </div>
          <h2>Login to platform</h2>
          <RenderUI />
        </div>
        <div className="Login-banner">
          <img alt="" src="/img/StanArtistLogin.png" />
        </div>
      </div>
    </>
  );
}
