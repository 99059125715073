import StanButton from "atomics/StanButton/StanButton";
import StanTypo from "component/StanTypo/StanTypo";
import React from "react";

const StanButtonTypo = ({
  type,
  danger,
  onClick,
  fontSize,
  fontWeight,
  content,
  color,
}) => {
  return (
    <StanButton type={type} danger={danger} onClick={onClick}>
      <StanTypo
        fontSize={fontSize}
        fontWeight={fontWeight}
        content={content}
        color={color}
      />
    </StanButton>
  );
};

export default StanButtonTypo;
