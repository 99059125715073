import { Form } from "antd";
import { useEffect, useState } from "react";
import { profileService } from "../../services/profileService";
import ProfileForm from "./ProfileComponent/ProfileForm/ProfileForm";
import ProfileHeader from "./ProfileComponent/ProfileHeader/ProfileHeader";
import "./UserProfile.css";
import { useDispatch, useSelector } from "react-redux";
import { getListProfile } from "../../redux/action/ProfileAction";
import { useTranslation } from "react-i18next";
import { openNotificationWithIcon } from "../../App";
import _ from "lodash";
import moment from "moment";
export default function UserProfile() {
  const { t } = useTranslation(["profile"]);

  const [isEdit, setIsEdit] = useState(false);
  const [form] = Form.useForm();
  const [images, setImages] = useState([{ data_url: "/svg/dummy-avatar.svg" }]);
  const [formValue, setFormValue] = useState();
  const [fileAvatar, setFileAvatar] = useState({});
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { listProfile } = useSelector((state) => state.ProfileReducer);
  const handleEditProfile = () => {
    setIsEdit(true);
  };
  useEffect(() => {
    if (listProfile.avatar) {
      setImages([{ data_url: listProfile?.avatar }]);
    }
    if (listProfile?.country?.name) {
      listProfile.country = listProfile.country?.name;
    }
    if (listProfile?.birthday) {
      listProfile.birthday = moment(listProfile?.birthday, "DD-MM-YYYY");
    }
    setFormValue(listProfile);
  }, [listProfile]);

  useEffect(() => {
    form.setFieldsValue(formValue);
    formValue?.avatar && setImages([{ data_url: formValue.avatar }]);
  }, [formValue]);
  const handleFinish = async (values) => {
    if (values.birthday) {
      values.birthday = moment(values.birthday).format("DD-MM-YYYY");
    }
    setIsLoading(true);
    const profileUpdate = await profileService.updateProfile({
      ..._.omit(values, ['country']),
      ...fileAvatar,
    });
    form.resetFields();
    setIsLoading(false);
    if (profileUpdate.data.status === "success") {
      openNotificationWithIcon("success", t("MSG.SUCCESS"));
      dispatch(getListProfile());
    } else {
      openNotificationWithIcon("error", t("VALIDATE.ERROR"));
      form.setFieldsValue(formValue);
    }
    setIsEdit(false);
  };
  const handleCancelEdit = () => {
    setIsEdit(false);
    form.resetFields();
    form.setFieldsValue(formValue);
  };
  return (
    <>
      <div className="user-profile-container">
        <h2>User Profile</h2>
        <Form layout="vertical" onFinish={handleFinish} form={form}>
          <div className="UserProfile mt-5">
            <ProfileHeader
              isLoading={isLoading}
              setFileAvatar={setFileAvatar}
              setFormValue={setFormValue}
              formValue={formValue}
              form={form}
              isEdit={isEdit}
              setImages={setImages}
              images={images}
              handleEditProfile={handleEditProfile}
              handleCancelEdit={handleCancelEdit}
            />
            <ProfileForm
              form={formValue}
              isEdit={isEdit}
              listProfile={formValue}
            />
          </div>
        </Form>
      </div>
    </>
  );
}
