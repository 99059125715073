import React from 'react'
import { Select } from 'antd';
import "./index.css";

export default function StanSelect({value,defaultValue, onChange, placeholder, options, wrapperClass,disabled}) {
  return (
    <div className={`stand-select-wrapper ${wrapperClass}`}>
      <Select
        size="large"
        value={value}
        allowClear
        onChange={onChange}
        placeholder={placeholder}
        options={options}
        disabled={disabled}
        defaultValue={defaultValue}
      />
    </div>
  )
}
