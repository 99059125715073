import { Button } from 'antd';
import "./index.css";
const StanButton = (props) => {
  return (
    <Button
      type={props.type}
      disabled={props.disabled}
      block={props.block}
      danger={props.danger}
      ghost={props.ghost}
      href={props.href}
      icon={props.icon}
      loading={props.loading}
      shape={props.shape}
      size={props.size || 'large'}
      onClick={props.onClick}
      htmlType={props.htmlType}
      style={props.customStyle}
      className={props.className}
    >
      {props.children}
    </Button>
  );
};

export default StanButton;
