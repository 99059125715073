import { isValidPhoneNumber } from "react-phone-number-input";
export const CheckPhoneNum = (value) => {
  const checkPhone = isValidPhoneNumber(value || "");
  if (value !== undefined && value !== null && !checkPhone) {
    return false;
  }
  return true;
};
export const CheckDomain = (value) => {
  let result =
    !/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}$/.exec(
      value
    );
  return result;
};
export const getVideoDuration = (file) =>
new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = () => {
    const media = new Audio(reader.result);
    media.onloadedmetadata = () => resolve(media.duration);
  };
  reader.readAsDataURL(file);
  reader.onerror = (error) => reject(error);
});

export  const getVideoCover = async (file, seekTo = 0.5)=> {
 
  return await new Promise((resolve, reject) => {
      // load the file to a video player
      const videoPlayer = document.createElement('video');
      videoPlayer.setAttribute('src', URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener('error', (ex) => {
          reject("error when loading video file", ex);
      });
      // load metadata of the video to get video duration and dimensions
       videoPlayer.addEventListener('loadedmetadata', () => {
          // seek to user defined timestamp (in seconds) if possible
          if (videoPlayer.duration < seekTo) {
              reject("video is too short.");
              return;
          }
          // delay seeking or else 'seeked' event won't fire on Safari
          setTimeout(() => {
            videoPlayer.currentTime = seekTo;
          }, 200);
          // extract video thumbnail once seeking is complete
          videoPlayer.addEventListener('seeked', async() => {
              console.log('video is now paused at %ss.', seekTo);
              // define a canvas to have the same dimension as the video
              const canvas = document.createElement("canvas");
              canvas.width = videoPlayer.videoWidth;
              canvas.height = videoPlayer.videoHeight;
              // draw the video frame to canvas
              const ctx = canvas.getContext("2d");
              ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
              // return the canvas image as a blob
              const dataUrl = await ctx.canvas.toDataURL("image/jpg");
              resolve(dataUrl)

          });
      });
  });

}

export const getBase64 = (file) =>
new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});
