import { DATE_FORMAT } from "common/Constant";
import moment from "moment";
import StanInput, {
  StandDateInput,
  StandSelect,
} from "../../atomics/StanInput";
import "./StanFilter.css";

export default function StanFilter(props) {
  const { optionsStatus, optionsType, filterOption, setFilterOption, searchPlaceholder } = props;
  const { fromDate, toDate, status, type, search } = filterOption
const handleChangeDate=(date,type)=>{
  const setDate=(date)=>{
    switch(type){
      case 'fromDate':  setFilterOption({...filterOption, fromDate: date });
      break;
      case 'toDate': setFilterOption({...filterOption, toDate:date})
      break;
    }
  }
  if(date){
   return setDate(moment(date))
  }
  setDate('')
}
  return (
    <div className="StanFilter">
      <StandDateInput
        placeholder="From date"
        format={DATE_FORMAT}
        onChange={date => handleChangeDate(date,"fromDate")}
        value={fromDate}
      />
      <StandDateInput
        placeholder="To date"
        format={DATE_FORMAT}
        onChange={date =>  handleChangeDate(date,"toDate")}
        value={toDate}
      />
      <StandSelect
        value={status}
        allowClear
        onChange={(value) => setFilterOption({...filterOption, status: value})}
        placeholder="Status"
        options={optionsStatus}
        wrapperClass="filter-width-24-percent"
      />
      {props.isDashboard && (
        <StandSelect
          value={type}
          allowClear
          onChange={(value) => setFilterOption({...filterOption, type: value})}
          placeholder="Type"
          options={optionsType}
          wrapperClass="filter-width-24-percent"
        />
      )}
      {/* <Input
        size="large"
        placeholder="Search by Title"
        onChange={(e) => setTitle(e.target.value)}
      /> */}
      <StanInput
        wrapperClass="filter-width-24-percent"
        placeholder={searchPlaceholder}
        type="search"
        value={search}
        onChange={e => setFilterOption({...filterOption, search: e.target.value})}
      />
    </div>
  );
}
