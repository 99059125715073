import { Spin } from "antd";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min";
import App from "./App";
import "./i18n";
import "./index.css";
import { store } from "./redux/configStore";
import reportWebVitals from "./reportWebVitals";
// import "bootstrap/dist/css/bootstrap.css";
ReactDOM.render(
  <Provider store={store}>
    <Suspense
      fallback={
        <>
          <div style={{ minHeight: "200vh" }}>
            <div className="d-flex flex-column align-items-center justify-content-center mt-5">
              <Spin />
              <span>Loading...</span>
              <div className="mt-5">
                <img width={300} alt="" src="/svg/stan_Logo.svg" />
              </div>
            </div>
          </div>
        </>
      }
    >
      <App />
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
