import { Col, Divider, Row } from "antd";
import React from "react";
import StanTypo from "../StanTypo/StanTypo";
import ListDetail from "./ListDetail/ListDetail";
import ListInteraction from "./ListInteraciton/ListInteraction";
import "./ViewDetail.css";
const ViewDetail = (props) => {
  const { listData, listInteractions } = props;
  return (
    <div className="detail-container">
      <div>
        <StanTypo fontSize="21" fontWeight="700" content={props.type} />
      </div>
      {listData?.map((item) => {
        return<ListDetail item={item}/>
      })}

      <Divider />

      <div>
        <StanTypo fontSize="21" fontWeight="700" content="Interactions" />
        <Row>
          {listInteractions?.map((item, index) => {
            return (
              <ListInteraction item={item} index={index}/>
            );
          })}
        </Row>
      </div>
    </div>
  );
};

export default ViewDetail;
