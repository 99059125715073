import Cookies from "js-cookie";

export const TOKEN_COOKIE = Cookies.get("TokenAW");
// STAN DOMAINS
export const DOMAIN = process.env.REACT_APP_DOMAIN;
export const DOMAIN_WS = process.env.REACT_APP_DOMAIN_WS;
export const DOMAIN_MEDIA = process.env.REACT_APP_DOMAIN_MEDIA;

// LOGIN WITH SOCIAL MEDIA CONFIG_IDs
export const APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const KAKAO_KEY = process.env.REACT_APP_KAKAO_KEY;
export const NAVER_KEY = process.env.REACT_APP_NAVER_KEY;
export const NAVER_SECRET = process.env.REACT_APP_NAVER_SECRET;
export const LINE_KEY = process.env.REACT_APP_LINE_KEY;
export const LINE_SECRET = process.env.REACT_APP_LINE_SECRET;
export const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;

// DEEP LINK KEY
export const DEEP_LINK_KEY = process.env.REACT_APP_DEEPLINK_KEY;
