import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { StanTokenReducer } from "./reducer/StanTokenReducer";
import { PostManagementReducer } from "./reducer/PostManagementReducer";
import { PopupAddEditReducer } from "./reducer/PopupAddEditReducer";
import { ProfileReducer } from "./reducer/ProfileReducer";
import { StreamReducer } from "./reducer/StreamReducer";
import { UserReducer } from "./reducer/UserReducer";
const rootReducer = combineReducers({
  StanTokenReducer,
  PostManagementReducer,
  PopupAddEditReducer,
  ProfileReducer,
  StreamReducer,
  UserReducer
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
