import { Col, Row } from 'antd';
import StanStatusBadge from 'atomics/StanStatusBadge';
import React from 'react'
import StanTypo from '../../StanTypo/StanTypo';
import moment from 'moment';

const ListDetail = (props) => {
  const {item}= props;

  return (
    <Row key={`listDetail ${item.value}`}>
            <Col span={8}>
              <StanTypo
                fontSize={14}
                fontWeight={400}
                color="#8D8D8D"
                content={item.lable}
              />
            </Col>
            <Col span={16}>
              {item.lable == "Status" ? (
                <StanStatusBadge type={'detail'} status={item.value} />
              ) : item.lable === "Tag" ? (
                <Row gutter={12}>
                  {item.value.map((tag) => {
                    return (
                      <Col key={`list detail ${tag}`}>
                        <StanTypo
                          fontSize={14}
                          fontWeight={600}
                          color="#0D0D0D"
                          content={`#${tag}`}
                        />
                      </Col>
                    );
                  })}
                </Row>
              ) : (
                <StanTypo
                  fontSize={14}
                  fontWeight={600}
                  color="#0D0D0D"
                  content={item.lable === 'Record Time' ?  moment().hours(0).minutes(0).seconds(item.value).format('mm:ss') : item.value}
                  className="description"
                />
              )}
            </Col>
          </Row>
  )
}

export default ListDetail