import React from 'react'
import AlertWarning from '../../component/AlertWarning';
import PostEditPopup from '../../component/PostEditPopup';
import { useDispatch } from 'react-redux';
import { SET_IS_OPEN } from '../../redux/types/PopupAddEditType';
export default function TestPage() {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState(true)
  const [videoSrc, setVideoSrc] = React.useState("");
  return (
    <div>
      TestPage 
      <button onClick={() => dispatch({type: SET_IS_OPEN, payload: {
        isOpen: true
      }})}>open</button>
      {/* <AlertWarning
        isCancelButton={true}
        buttonColor="#D92846"
        isOpen={isOpen}
        onOK={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        title="tets"
        desc="test"
        btnText="Got it"
      /> */}
      <PostEditPopup isOpen={isOpen} title="test" onOke={() => {}} onCancel={() => setIsOpen(false)}/>
    </div>
  )
}
