import { Button } from "antd";
import { FacebookIcon } from "common/commonSvg";
import StanTypo from "component/StanTypo/StanTypo";
import React, { Component } from "react";
import SocialLogin from "react-social-login"

class SocialButton extends Component {
  render() {
    const { children, triggerLogin, ...props } = this.props;
    return (
      <div>
        <Button
          className="btn-facebookLogin"
          onClick={() => triggerLogin()}
          {...props}
        >
          <FacebookIcon />
          <StanTypo
            fontSize={14}
            fontWeight={500}
            color="#FFFFFF"
            content="Sign in with Facebook"
          />
        </Button>
      </div>
    );
  }
}

export default SocialLogin(SocialButton);
