export const SET_IS_OPEN = "SET_IS_OPEN";
export const SET_IS_EDIT = "SET_IS_EDIT";
export const SET_ACTION_TYPE = "SET_ACTION_TYPE";
export const SET_VIDEO_SRC = "SET_VIDEO_SRC";
export const SET_LIST_IMGS = "SET_LIST_IMGS";
export const SET_POST_STATE = "SET_POST_STATE";
export const SET_STREAM_STATE = "SET_STREAM_STATE";
export const SET_LIST_USER_TAGGED = "SET_LIST_USER_TAGGED";

export const ActionType = {
  addPost: "add_post",
  editPost: "edit_post",
  addStream: "add_stream",
  editStream: "edit_stream"
}