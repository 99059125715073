import Cookies from "js-cookie";
import {
  PERMISSION_DENIED,
  PERMISSION_EXPIRED,
  PERMISSION_PENDING,
  PERMISSION_SENT,
} from "redux/types/UserType";
import { io } from "socket.io-client";
import { history, openNotificationWithIcon } from "../../App";
import { PERMISSION } from "../../common/Constant";
import { DOMAIN_WS } from "../../util/settings/config";
import { clearAllCookies } from "../../util/settings/utils";

export const handleSocketLogin = (token, type) => {
  return async (dispatch) => {
    try {
      const socket = io(DOMAIN_WS, {
        transports: ["websocket"],
      });
      socket.on("connect", () => {
        console.log("CONNECTED SOCKET");
      });
      socket.emit("AW_LOGIN", { token: token, type: type });
      socket.on("AW_LOGIN_SUCCESS", function (data) {
        console.log("EMIT SUCCESS", data);
        if (data && data.status !== "error") {
          dispatch({ type: PERMISSION_SENT });
        }
        if (data && data.status === "error") {
          openNotificationWithIcon("error", "Error", data.message);
          dispatch({ type: PERMISSION_PENDING });
        }
      });
      socket.on("RESPONSE_REQUEST", function (data) {
        console.log("DATA NE =>", data);
        if (data && data.action === PERMISSION.APPROVED) {
          if (data?.data?.user?.isManager) {
            localStorage.setItem("ManagerToken", data.data.token);
          }
          Cookies.set("TokenAW", data.data.token);
          localStorage.setItem("UserProfile", JSON.stringify(data.data.user));
          history.push("/dashboard");
          socket.disconnect();
          window.location.reload();
        } else if (data && data.action === PERMISSION.DENIED) {
          dispatch({ type: PERMISSION_DENIED });
        } else if (data && data.action === PERMISSION.REPORTED) {
          openNotificationWithIcon(
            "error",
            "Error",
            "Your account has been deactivated by our admin! Please contact Stan Admin for support"
          );
        } else if (data && data.action === PERMISSION.EXPIRED) {
          dispatch({ type: PERMISSION_EXPIRED });
        }
      });
    } catch (error) {
      console.log(error);
      openNotificationWithIcon(
        "error",
        "Error",
        "Something went wrong! Please try again later!"
      );
      dispatch({ type: PERMISSION_PENDING });
    }
  };
};

export const handleSwitchAccount = (
  artist,
  setIsRequesting,
  alertState,
  setAlertState,
  callback
) => {
  return async (dispatch) => {
    try {
      const socket = io(DOMAIN_WS, {
        transports: ["websocket"],
      });
      socket.on("connect", () => {
        console.log("CONNECTED SOCKET");
      });
      socket.emit("AW_SWITCH_ACCOUNT", {
        token: Cookies.get("TokenAW"),
        userId: artist.user.id,
      });
      socket.on("AW_SWITCH_ACCOUNT_SUCCESS", function (data) {
        console.log("SWITCH ACCOUNT =>", data);
        setIsRequesting(true);
        if (data && data.status === "error") {
          setIsRequesting(false);
          openNotificationWithIcon("error", "Error", data.message);
          if (data.code === "YOU_ARE_NOT_AUTHORIZED_AW") {
            setAlertState({
              isOpen: true,
              buttonClor: "#49D19A",
              btnText: "Got it",
              title: "You have been logged out",
              desc: "Your account has been removed by Artist. You no longer access this account.",
              onOK: () => {
                setAlertState({ ...alertState, isOpen: false });
                callback();
              },
            });
          }
        }
      });
      socket.on("RESPONSE_REQUEST", function (data) {
        console.log("SWITCH RESPONSE =>", data);
        if (data && data.action === PERMISSION.APPROVED) {
          Cookies.set("TokenAW", data?.data.token);
          localStorage.setItem("UserProfile", JSON.stringify(data.data.user));
          window.location.reload();
        } else if (data && data.action === PERMISSION.DENIED) {
          setIsRequesting(false);
          setAlertState({
            isOpen: true,
            buttonClor: "#D92846",
            btnText: "Got it",
            title: "Access denied!",
            desc: "Please contact your artist or try again.",
            onOK: () => {
              setAlertState({ ...alertState, isOpen: false });
              callback();
            },
          });
        } else if (data && data.action === PERMISSION.REPORTED) {
          setIsRequesting(false);
          setAlertState({
            isOpen: true,
            buttonClor: "#D92846",
            btnText: "Got it",
            title: "Your access has been reported!",
            desc: "Please contact your artist or try again.",
            onOK: () => {
              setAlertState({ ...alertState, isOpen: false });
              callback();
            },
          });
        } else if (data && data.action === PERMISSION.EXPIRED) {
          setIsRequesting(false);
          setAlertState({
            isOpen: true,
            buttonClor: "#D92846",
            btnText: "Got it",
            title: "Expired!",
            desc: "Your request to log in has expired. Please try again",
            onOK: () => {
              setAlertState({ ...alertState, isOpen: false });
              callback();
            },
          });
        }
      });
      setAlertState({
        isOpen: true,
        buttonClor: "#49D19A",
        btnText: "Got it",
        desc: "Request has been sent to your Stan app. Please grant permission to log in.",
        isCancelButton: false,
        onOK: () => setAlertState({ ...alertState, isOpen: false }),
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const logOut = () => {
  try {
    clearAllCookies();
    localStorage.clear();
    history.push("/login");
    window.location.reload();
  } catch (error) {
    console.log(error);
  }
};
