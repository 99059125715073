export const getMMDDYYYY = (date) => {
  var dateObj = new Date(date);
  var month = dateObj.getUTCMonth() + 1; //months from 1-12
  var day = dateObj.getUTCDate();
  var year = dateObj.getUTCFullYear();

  return month + "/" + day + "/" + year;
};

export const getHHMMSS = (date) => {
  var dateObj = new Date(date);
  var seconds = dateObj.getSeconds();
  var minutes = dateObj.getMinutes();
  var hours = dateObj.getHours();

  return hours + ":" + minutes + ":" + seconds;
};

const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};
export const getDisabledTime = (date) => {
  const isCurrentDate = new Date(date).getDate() === new Date().getDate();
  const currentHour = new Date().getHours();
  const currentMin = new Date().getMinutes();
  return () => {
    return {
      disabledHours: isCurrentDate ? () => range(0, currentHour) : () => [],
      disabledMinutes: (selectedHour) => {
        return isCurrentDate && selectedHour === currentHour
          ? range(0, currentMin)
          : [];
      },
      disabledSeconds: () => [],
    };
  };
};

export const handleImagesSize = (images) => {
  let filePaths = [...images];
  const ratio = filePaths[0]?.width / filePaths[0]?.height;
  for(let i=1; i<filePaths.length; i++) {
    if(filePaths[i].width/filePaths[i].height > ratio) {
      filePaths[i].width = Math.round(ratio * filePaths[i].height);
    } else {
      filePaths[i].height = Math.round(filePaths[i].width / ratio);
    }
  }
  return filePaths;
}
