import React, { useState, useEffect, useTransition } from "react";
import { Modal, Checkbox } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import StandButton from "../../atomics/StanButton/StanButton";
import StanInput, {
  StandDateInput,
  StandTimeInput,
  StanTextArea,
  StandSelect,
} from "../../atomics/StanInput";
import StanImageUpload from "../../atomics/StandUpload/image-upload";
import { useSelector, useDispatch } from "react-redux";
import {
  SET_POST_STATE,
  SET_IS_OPEN,
  ActionType,
} from "../../redux/types/PopupAddEditType";
import useAddAndEditPost from "./useAddAndEditPost";
import "./index.css";
import moment from "moment";
import { openNotificationWithIcon } from "App";
import { useTranslation } from "react-i18next";
import { DATE_FORMAT, TIME_FORMAT } from "../../common/Constant";
import { getDisabledTime } from "../../util/helper";
import {
  IMAGE_ACCEPT_TYPE,
  IMAGE_TYPE_POST,
  RULES,
} from "../../common/Constant";
import { getBase64, getVideoCover, getVideoDuration } from "common/common";

export default function PostEditPopup({ onOK, onCancel, onSuccess }) {
  const { isOpen, actionType, postState } = useSelector((state) => {
    return state.PopupAddEditReducer;
  });
  const { t } = useTranslation(["postManagement"]);
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [isSquare, setIsSquare] = useState(false);
  const [taggedUserIds, setTaggedUserIds] = useState([]);
  const dateErr =
    isSaveClicked && postState.isPublic && !postState.date
      ? "*Please select a date!"
      : "";
  const timeErr =
    isSaveClicked && postState.isPublic && !postState.date
      ? "*Please select a time!"
      : "";
  // const [listUserSelected, setListUserSelected] = useState([]);
  const showWarningMedia = postState?.listImgs?.length ? false : true;

  const onClose = () =>
    dispatch({
      type: SET_IS_OPEN,
      payload: {
        isOpen: false,
      },
    });
  const dispatch = useDispatch();
  var reader = new FileReader();

  reader.onload = function (e) {
    var image = new Image();
    image.onload = function () {
      let listImgs = postState.listImgs;
      listImgs[listImgs.length - 1] = {
        ...listImgs[listImgs.length - 1],
        width: this.width,
        height: this.height,
      };
      dispatch({
        type: SET_POST_STATE,
        payload: {
          postState: { ...postState, listImgs: [...listImgs] },
        },
      });
    };
    image.src = e.target.result;
  };
  const handleImageChange = async (files) => {
    const { file, fileList } = files;
    let newFileList = postState.listImgs || [];
    const updateListFile = () => {
      dispatch({
        type: SET_POST_STATE,
        payload: {
          postState: { ...postState, listImgs: [...newFileList] },
        },
      });
    };
    if (file?.status === "removed") {
      let index = newFileList.findIndex((item) => file.uid === item.uid);
      newFileList.splice(index, 1);
      updateListFile();
      return;
    }

    if (newFileList.length >= 10) {
      openNotificationWithIcon("error", t("MSG.MAX_10"));
      return;
    }
    if (!IMAGE_TYPE_POST.includes(file.type)) {
      openNotificationWithIcon("error", t("MSG.WRONG_FILE"));
      return;
    }
    if (file.type === "video/mp4") {
      let videoDuration = await getVideoDuration(file);

      if (videoDuration >= 600) {
        openNotificationWithIcon("error", t("MSG.LIMIT_TIME"));
        return;
      }
      const coverImg = await getVideoCover(file);
      file.thumbUrl = coverImg;
    }
    if (file.type !== "video/mp4") {
      const result = await getBase64(file);
      file.thumbUrl = result;
      file.originFileObj = file;
    }
    newFileList.push(file);
    updateListFile();
  };

  const title =
    actionType === ActionType.addPost ? "Add new post" : "Edit post";
  const subTitle = "Add media";
  const {
    onSave,
    fetchListUserFollow,
    listUserFollow,
    isLoading,
    getDetailPost,
    listUserSelected,
  } = useAddAndEditPost(onClose, actionType, onSuccess, postState?.id);
  const isDisableDateTime =
    !postState.isPublic ||
    (postState.startTime &&
      postState.startTime < new Date().getTime() / 1000) ||
    (!postState.startTime && postState.createdAt);
  const listUsersOption = [];
  listUserFollow.forEach((user) => {
    if (postState?.taggedUserIds?.indexOf(user.id) < 0)
      listUsersOption.push({ label: user.name, value: user.id });
  });
  const setPostState = (postState) => {
    dispatch({
      type: SET_POST_STATE,
      payload: { postState: postState },
    });
  };
  const onSelectUser = (value) => {
    let listTaggedUserIds = [...postState.taggedUserIds];
    const user = listUserFollow.find((item) => item.id === value);
    listTaggedUserIds.push(user.id);
    setTaggedUserIds(listTaggedUserIds);
    dispatch({
      type: SET_POST_STATE,
      payload: {
        postState: { ...postState, taggedUserIds: [...listTaggedUserIds] },
      },
    });
    // setListUserSelected([...listUserSelected, user]);
  };
  const onUnselectUser = (unSelectUser) => {
    let taggedUserIds = postState.taggedUserIds;
    let index = taggedUserIds.indexOf(unSelectUser.id);
    taggedUserIds.splice(index, 1);
    setTaggedUserIds(taggedUserIds);
    dispatch({
      type: SET_POST_STATE,
      payload: {
        postState: { ...postState, taggedUserIds: [...taggedUserIds] },
      },
    });
    // setListUserSelected([...newUserSelected]);
  };
  const verifyPost = () => {
    if (!postState.listImgs.length) return false;
    if (postState.isPublic) {
      if (!postState.date || !postState.time) {
        return false;
      }
    }
    return true;
  };
  const onClickSave = () => {
    if (!verifyPost()) setIsSaveClicked(true);
    else onSave(postState, isSquare, listUserSelected);
  };
  const UserTagGroup = (
    <div className="user-tag-group">
      {listUserSelected?.map((user) => (
        <div className="user-tag" key={user.id}>
          <div className="user-tag-name">{user.name}</div>
          <div
            className="user-tag-close-icon"
            onClick={() => onUnselectUser(user)}
          >
            &nbsp;&nbsp;&nbsp;X
          </div>
        </div>
      ))}
    </div>
  );
  useEffect(() => {
    fetchListUserFollow();
  }, []);
  useEffect(() => {
    isOpen && getDetailPost();
  }, [isOpen]);
  return (
    <Modal
      open={isOpen}
      onOK={onOK}
      onCancel={onCancel}
      footer={null}
      closable={false}
      wrapClassName="popup-edit-post-container"
    >
      <div className="post-edit-content-wrapper mb-3">
        <div className="post-edit-title">{title}</div>
      </div>
      {/* <StanInput
        value={postState.title}
        onChange={(event) => {
          event.target.value.length < 151 &&
            setPostState({ ...postState, title: event.target.value });
        }}
        placeholder="Title"
      />
      {postState.title.length === 150 && (
        <div className="stan-warning-error">*Max length is 150</div>
      )}
      <hr /> */}
      <div className="media-section">
        <div className="media-header">
          <div className="sub-title">{subTitle}</div>
          <Checkbox
            checked={isSquare}
            onChange={(event) => setIsSquare(event.target.checked)}
          >
            Square view
          </Checkbox>
        </div>
        <div>
          <div className="upload-image-section">
            <StanImageUpload
              maxCount={10}
              listImgs={postState.listImgs}
              handleChange={handleImageChange}
              accept={IMAGE_TYPE_POST}
              disabled={actionType === ActionType.editPost}
            />
            {showWarningMedia && isSaveClicked && (
              <div className="stan-warning-error">*Media is required</div>
            )}
            <hr />
            <div>
              <StanTextArea
                value={postState.description}
                onChange={(event) =>
                  setPostState({
                    ...postState,
                    description: event.target.value,
                  })
                }
                placeholder="Description"
              />
            </div>
            <hr />
            <div className="popup-edit-date-time-group">
              <Checkbox
                checked={postState.isPublic}
                onChange={(event) =>
                  setPostState({ ...postState, isPublic: event.target.checked })
                }
                disabled={
                  postState.startTime &&
                  postState.startTime < new Date().getTime() / 1000
                    ? true
                    : false
                }
              >
                Public on
              </Checkbox>
              <div>
                <StandDateInput
                  value={moment(postState.date, DATE_FORMAT)}
                  onChange={(date) => {
                    setPostState({ ...postState, date: moment(date, DATE_FORMAT) });
                  }}
                  placeholder="Date"
                  disabled={isDisableDateTime}
                  format={DATE_FORMAT}
                  disabledDate={(current) =>
                    moment().add(-1, "days") >= current
                  }
                />
                <div className="stan-warning-error margin-bottom--24">
                  {dateErr}
                </div>
              </div>
              <div>
                <StandTimeInput
                  value={moment(postState.time, TIME_FORMAT)}
                  onChange={(time) =>
                    setPostState({ ...postState, time: moment(time) })
                  }
                  placeholder="Time"
                  disabled={isDisableDateTime}
                  format={TIME_FORMAT}
                  disabledTime={getDisabledTime(postState.date)}
                />
                <div className="stan-warning-error margin-bottom--24">
                  {timeErr}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="user-tags-section">
          {UserTagGroup}
          <StandSelect
            value={"User tags"}
            allowClear
            onChange={(value) => onSelectUser(value)}
            placeholder="User tags"
            options={listUsersOption || []}
            wrapperClass=""
          />
        </div>
        <div className="popup-edit-post-footer">
          <StandButton
            onClick={onCancel}
            className="cancel-button half-width-btn"
          >
            Cancel
          </StandButton>
          <StandButton
            onClick={onClickSave}
            className="OK-button half-width-btn"
            type="primary"
            loading={isLoading}
          >
            {postState.isPublic ? "Save & Public" : "Save"}
          </StandButton>
        </div>
      </div>
    </Modal>
  );
}
