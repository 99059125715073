import React from "react";
import { Input } from "antd";
import StandDateInput from "./date-time-input/date-input";
import StandTimeInput from "./date-time-input/time-input";
import StanTextArea from "./text-area";
import StandSelect from "./select-input";
import { AudioOutlined } from '@ant-design/icons';

import "./index.css";

export { StandDateInput, StandTimeInput, StanTextArea, StandSelect };

export default function StanInput({ wrapperClass, placeholder, type, onEnter, value, onChange }) {
  const { Search } = Input;
const [testValue, setTestValue] = React.useState("test value")
  return (
    <div className={`stand-custom-input ${wrapperClass}`}>
      {type === "search" ? (
        <span className="search-input">
          <Search
            placeholder={placeholder}
            onSearch={onEnter}
            style={{ width: 200 }}
            value={value}
            onChange={onChange}
          />
        </span>
      ) : (
        <Input value={value} placeholder={placeholder} size="large" onChange={onChange} bordered={true} />
      )}
    </div>
  );
}
