import { Col, Row } from "antd";
import StanButton from "atomics/StanButton/StanButton";
import StanButtonTypo from "component/StanButtonTypo/StanButtonTypo";
import StanTypo from "component/StanTypo/StanTypo";
import React from "react";
import { useTranslation } from "react-i18next";

const ProfileHeaderButton = (props) => {
  const {
    isLoading,
    isEdit,
    handleEditProfile,
    handleCancelEdit,
    formValue,
    setImages,
    setImageList,
    handleEdit,
  } = props;
  const { t } = useTranslation(["profile"]);
  const resetImage = () => {
    if (formValue?.avatar) {
      setImages([{ data_url: formValue?.avatar }]);
    } else {
      setImages([{ data_url: "/svg/dummy-avatar.svg" }]);
    }
    setImageList();
  };
  const handleCancel = () => {
    resetImage();
    handleCancelEdit();
  };

  return (
    <div className="header-right">
      {!isEdit ? (
        <StanButton type="primary" onClick={() => handleEditProfile()}>
          <StanTypo
            fontSize={18}
            fontWeight={500}
            content={t("ACTION.EDIT_PROFILE")}
            color="white"
          />
        </StanButton>
      ) : (
        <Row gutter={24}>
          <Col>
            <StanButtonTypo
              type="primary"
              danger
              onClick={handleCancel}
              fontSize={18}
              fontWeight={500}
              content={t("ACTION.CANCEL")}
              color="white"
            />
          </Col>
          <Col>
            <StanButtonTypo
              loading={isLoading}
              type="primary"
              onClick={handleEdit}
              fontSize={18}
              fontWeight={500}
              content={t("ACTION.SAVE")}
              color="white"
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ProfileHeaderButton;
