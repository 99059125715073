import { Modal } from "antd";
import React, { useEffect } from "react";
import StanTypo from "../../../../component/StanTypo/StanTypo";
import AddEditStreamForm from "../AddEditStreamForm/AddEditStreamForm";
import "./StreamModal.css";
const StreamModal = (props) => {
  useEffect(() => {
  }, [props.isOpenModal]);
  return (
    <Modal
      className="stream-modal"
      visible={props.isOpenModal}
      title={
        props.type === "add" ? (
          <StanTypo
            fontSize="20px"
            fontWeight="bold"
            content="Add new Livestream"
          />
        ) : (
          <StanTypo
            fontSize="20px"
            fontWeight="bold"
            content="Edit Livestream"
          />
        )
      }
      width={500}
      footer={false}
      onCancel={props.closeModal}
    >
      <AddEditStreamForm
        type={props.type}
        streamInfor={props.streamInfor}
        closeModal={() => props.closeModal()}
        getListStream={props.getListStream}
        onUpdate={props.onUpdate}
      />
    </Modal>
  );
};

export default StreamModal;
