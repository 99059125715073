import React from "react";
import { Modal } from "antd";
import StandButton from "../../atomics/StanButton/StanButton";
import "./alertWarning.css";

export default function AlertWarning({
  isOpen,
  onOK,
  onCancel,
  buttonColor,
  isCancelButton,
  title,
  desc,
  btnText,
  danger,
  type,
  cancelText = "Cancel",
}) {
  const customStyle = {
    background: buttonColor,
  };
  return (
    <Modal
      open={isOpen}
      onOK={onOK}
      onCancel={onCancel}
      footer={null}
      closable={false}
      wrapClassName="popup-alert-container"
    >
      <div className="alert-popup-content-holder">
        <div className="warning-icon-holder">
          <img
            alt=""
            className="warning-icon"
            src="/img/icons/alert_warning.svg"
          />
        </div>
        <div className="alert-content-group">
          <div className="highlight-alert">{title}</div>
          <div className="alert-des">{desc}</div>
        </div>
        <div className="alert-popup-footer">
          {isCancelButton && (
            <StandButton
              onClick={onCancel}
              className="cancel-button alert-button half-width-btn"
            >
              {cancelText}
            </StandButton>
          )}
          <StandButton
            danger={danger}
            type='primary'
            className={
              isCancelButton
                ? "OK-button alert-button half-width-btn"
                : "OK-button alert-button"
            }
            customStyle={{
              ...customStyle,
            }}
            onClick={onOK}
          >
            {btnText}
          </StandButton>
        </div>
      </div>
    </Modal>
  );
}
