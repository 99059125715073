import Axios from "axios";
import Cookies from "js-cookie";
import { clearAllCookies } from "util/settings/utils";
import { history, openNotificationWithIcon } from "../App";
const { DOMAIN_MEDIA, TOKEN_COOKIE } = require("../util/settings/config");
export class baseService {
  put = async (url, model) => {
    return Axios({
      url: `${DOMAIN_MEDIA}/${url}`,
      method: "PUT",
      data: model,
      headers: {
        Authorization: "Bearer " + TOKEN_COOKIE,
      },
    })
      .then((data) => {
        return data;
      })
      .catch(function (error) {
        if (error?.response?.data) {
          if (error.response.status === 401) {
            clearAllCookies();
            Cookies.remove("TokenAW");
            openNotificationWithIcon(
              "error",
              "",
              error.response.data.message,
              history.push("/login")
            );
          }
        } else {
          openNotificationWithIcon(
            "error",
            "Warning",
            "Something is wrong! Please try again!"
          );
          history.push("/login");
        }
      });
  };

  postWithoutToken = (url, model) => {
    return Axios({
      url: `${DOMAIN_MEDIA}/${url}`,
      method: "POST",
      data: model,
    });
  };

  post = async (url, model) => {
    return Axios({
      url: `${DOMAIN_MEDIA}/${url}`,
      method: "POST",
      data: model,
      headers: {
        Authorization: "Bearer " + TOKEN_COOKIE,
      },
    }).catch(function (error) {
      if (error?.response?.data) {
        if (error.response.status === 401) {
          clearAllCookies();
          Cookies.remove("TokenAW");
          openNotificationWithIcon(
            "error",
            "",
            error.response.data.message,
            history.push("/login")
          );
        }
      }
    });
  };

  get = async (url) => {
    return Axios({
      url: `${DOMAIN_MEDIA}/${url}`,
      method: "GET",
      headers: {
        Authorization: "Bearer " + TOKEN_COOKIE,
      },
    }).catch(function (error) {
      if (error?.response?.data) {
        if (error.response.status === 401) {
          clearAllCookies();
          Cookies.remove("TokenAW");
          openNotificationWithIcon(
            "error",
            "",
            error.response.data.message,
            history.push("/login")
          );
        }
      }
    });
  };

  delete = async (url) => {
    return Axios({
      url: `${DOMAIN_MEDIA}/${url}`,
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + TOKEN_COOKIE,
      },
    }).catch(function (error) {
      if (error?.response?.data) {
        if (error.response.status === 401) {
          clearAllCookies();
          Cookies.remove("TokenAW");
          openNotificationWithIcon(
            "error",
            "",
            error.response.data.message,
            history.push("/login")
          );
        }
      }
    });
  };

  patch = async (url) => {
    return Axios({
      url: `${DOMAIN_MEDIA}/${url}`,
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + TOKEN_COOKIE,
      },
    }).catch(function (error) {
      if (error?.response?.data) {
        if (error.response.status === 401) {
          clearAllCookies();
          Cookies.remove("TokenAW");
          openNotificationWithIcon(
            "error",
            "",
            error.response.data.message,
            history.push("/login")
          );
        }
      }
    });
  };
}

export const getPresignMedia = (type, file) => {
  let url = `${DOMAIN_MEDIA}/api/v1/medias/presigned?type=${type}&`;
  if (file.type === "image/gif") {
    url += `extention=gif`;
  }
  return Axios({
    url: url,
    method: "GET",
    headers: {
      Authorization: "Bearer " + TOKEN_COOKIE,
    },
  });
};
