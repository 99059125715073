import React from 'react';
import './index.css';
import { Input } from 'antd';


const { TextArea } = Input;

const StanTextArea = ({value, onChange, placeholder}) => (
  <div className='stand-textarea'>
    <TextArea value={value} rows={4} autoSize={{ minRows: 3, maxRows: 5 }} placeholder={placeholder} onChange={onChange}/>
  </div>
);

export default StanTextArea;