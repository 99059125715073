import { Image, Table } from "antd";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath } from "react-router";
import { history } from "../../App";
import { LockIcon, LockOpenIcon } from "../../common/commonSvg";
import PostEditPopup from "../../component/PostEditPopup";
import ActionFormatter from "../../component/ActionFormatter/ActionFormatter";
import StanButton from "../../component/StanButton/StanButton";
import StanFilter from "../../component/StanFilter/StanFilter";
import StanTypo from "../../component/StanTypo/StanTypo";
import AlertWarning from "../../component/AlertWarning";
import usePostManage from "./usePostManage";
import {
  SET_ACTION_TYPE,
  SET_IS_OPEN,
  SET_POST_STATE,
} from "../../redux/types/PopupAddEditType";
import { ActionType } from "../../redux/types/PopupAddEditType";
import { initialState } from "../../redux/reducer/PopupAddEditReducer";
import "./PostManagement.css";
import { STANDARD_STATUS } from "../../util/common-types";
import { PAGE_SIZE } from "common/Constant";

export const PostType = {
  blocked: 0,
  active: 1,
};

export default function PostManagement() {
  const { postState } = useSelector((state) => state.PopupAddEditReducer);
  const dispatch = useDispatch();
  const [selectedPost, setSelectedPost] = useState([]);
  const [alertState, setAlertState] = useState({
    isOpen: false,
    buttonClor: "#D92846",
    btnText: "Delete",
    desc: "Are you sure you want to delete selected items?",
    isCancelButton: true,
  });
  const {
    listPost,
    page,
    filterOption,
    setFilterOption,
    fetchListPost,
    totalItems,
    handleTableChange,
    deletePost,
    loading,
    onSort,
    sort
  } = usePostManage();
  const pageSize = PAGE_SIZE;
  const pagination = {
    current: page,
    pageSize,
    total: totalItems,
  };
  useEffect(() => {
    fetchListPost();
  }, [
    page,
    filterOption.fromDate,
    filterOption.toDate,
    filterOption.status,
    filterOption.search,
    sort,
  ]);
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Thumbnail",
      dataIndex: "thumbnail",
      key: "thumbnail",

      render: (text) => {
        return (
          <>
            <Image className="postThumbnail " src={text} width={84} />
          </>
        );
      },
    },
    {
      title: "Creator",
      dataIndex: "ownerName",
      key: "ownerName",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Reports",
      dataIndex: "totalReport",
      key: "totalReport",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Likes",
      dataIndex: "totalLike",
      key: "totalLike",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Points",
      dataIndex: "totalPoint",
      key: "totalPoint",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Comments",
      dataIndex: "totalComment",
      key: "totalComment",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) =>
        text === STANDARD_STATUS.blocked ? <LockIcon /> : <LockOpenIcon />,
    },
    {
      title: (
        <span style={{ color: "#FFF" }}>
          <img
            className="table-sort-icon"
            onClick={onSort}
            src="svg/table/sort-icon.svg"
          />{" "}
          Created at
        </span>
      ),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Actions",
      key: "action",
      render: (text, row) => {
        return (
          <ActionFormatter
            showView
            showEdit
            showDelete
            handleView={() =>
              history.push(
                generatePath("/post-management/:postId", {
                  postId: row.id,
                })
              )
            }
            handleDelete={() => deleteSinglePost(row.id)}
            handleEdit={() => handleEdit(row)}
          />
        );
      },
    },
  ];
  const handleEdit = (row) => {
    const { title, description, media, startTime, id, taggedUserIds } = row;
    dispatch({
      type: SET_ACTION_TYPE,
      payload: { actionType: ActionType.editPost },
    });
    dispatch({
      type: SET_POST_STATE,
      payload: {
        postState: {
          ...postState,
          title,
          description,
          listImgs: media,
          id,
          taggedUserIds,
        },
      },
    });
    dispatch({ type: SET_IS_OPEN, payload: { isOpen: true } });
  };
  function deleteMultiPost() {
    let postIds = [];
    selectedPost.map((post, index) => {
      return postIds.push(post.id);
    });
    // dispatch(deletePost(data));
    deletePost(postIds);
    setAlertState({
      ...alertState,
      isOpen: false,
    });
    setSelectedPost([]);
  }

  function deleteSinglePost(postId) {
    deletePost([postId]);
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedPost([...selectedRows]);
    },
  };

  return (
    <>
      <div style={{ minHeight: "100vh" }}>
        <span>Post Management</span>
        <div className="text-right">
          <StanButton
            type="primary"
            onClick={() => {
              dispatch({
                type: SET_ACTION_TYPE,
                payload: { actionType: ActionType.addPost },
              });
              dispatch({
                type: SET_POST_STATE,
                payload: {
                  postState: { ...initialState.postState, listImgs: [] },
                },
              });
              dispatch({ type: SET_IS_OPEN, payload: { isOpen: true } });
            }}
          >
            <StanTypo
              fontSize={14}
              fontWeight={500}
              color="#FFFFFF"
              content="Add new Post"
            />
          </StanButton>
        </div>
        <StanFilter
          setFilterOption={setFilterOption}
          filterOption={filterOption}
          optionsStatus={[
            { label: "All", value: null },
            { label: "Blocked", value: 0 },
            { label: "Active", value: 1 },
            { label: "Image", value: "IMAGE" },
            { label: "Video", value: "VIDEO" },
          ]}
          searchPlaceholder={"Search by Title"}
        />
        <div
          className="mb-3"
          style={selectedPost.length < 1 ? { display: "none" } : null}
        >
          <StanButton
            type="primary"
            danger
            onClick={() =>
              setAlertState({
                ...alertState,
                isOpen: true,
              })
            }
          >
            <StanTypo
              fontSize={14}
              fontWeight={500}
              color="#FFFFFF"
              content="Delete"
            />
          </StanButton>
        </div>
        <div className="PostManagement-tableContainer">
          <Table
            rowKey={(row) => row.id}
            rowSelection={{
              ...rowSelection,
            }}
            columns={columns}
            dataSource={listPost}
            pagination={pagination}
            onChange={handleTableChange}
            loading={loading}
          />
        </div>
        <PostEditPopup
          onCancel={() =>
            dispatch({ type: SET_IS_OPEN, payload: { isOpen: false } })
          }
          onSuccess={fetchListPost}
        />
        <AlertWarning
          onCancel={() => {
            setAlertState({ ...alertState, isOpen: false });
          }}
          isOpen={alertState.isOpen}
          onOK={deleteMultiPost}
          buttonColor={alertState.buttonClor}
          btnText={alertState.btnText}
          isCancelButton={alertState.isCancelButton}
          desc={alertState.desc}
        />
      </div>
    </>
  );
}
