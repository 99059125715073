import { Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import { StandDateInput } from "../../../../atomics/StanInput";
import {
  BrowserIcon,
  CalendarIcon,
  EmailIcon,
  LocationIcon,
} from "../../../../common/commonSvg";
import { DATE_FORMAT, RULES } from "../../../../common/Constant";
import InputItem from "./InputItem/InputItem";
import "./profileform.css";

const ProfileForm = (props) => {
  const { form, isEdit, listProfile } = props;
  const { t } = useTranslation(["profile"]);

  return (
    <div className="content-profile-form">
      <InputItem
        isDisable={true}
        icon={<EmailIcon />}
        name="email"
        label={t("LABEL.Email")}
      >
        <Input placeholder={t("PLACEHOLDER.EMAIL")} disabled={true} />
      </InputItem>
      <InputItem
        name="country"
        icon={<LocationIcon />}
        label={t("LABEL.COUNTRY")}
        isDisable={true}
      >
        <Input disabled={true} placeholder={t("PLACEHOLDER.COUNTRY")}></Input>
      </InputItem>
      <InputItem
        name="birthday"
        icon={<CalendarIcon />}
        label={t("LABEL.BIRTHDAY")}
      >
        <StandDateInput
          disabled={!isEdit}
          format={DATE_FORMAT}
          placeholder={t("PLACEHOLDER.BIRTHDAY")}
        />
      </InputItem>
      <InputItem
        name="description"
        icon={<BrowserIcon />}
        label={t("LABEL.BIO")}
      >
        {!isEdit ? (
          <Input
            maxLength={500}
            disabled={true}
            placeholder={t("PLACEHOLDER.BIO")}
          />
        ) : (
          <TextArea
            placeholder={t("PLACEHOLDER.BIO")}
            autoSize={{ maxRows: 4 }}
            maxLength={500}
          />
        )}
      </InputItem>
    </div>
  );
};

export default ProfileForm;
