import { history, openNotificationWithIcon } from "App";
import { DASHBOARD_ITEMS_VALUE, PAGE_SIZE } from "common/Constant";
import { useEffect, useState } from "react";
import { generatePath } from "react-router";
import { streamService } from "services/streamService";
import { postManagementService } from "services/postManagementService";
import { dashboardService } from "../../services/dashboardService";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionType,
  SET_ACTION_TYPE,
  SET_IS_OPEN,
  SET_POST_STATE,
} from "redux/types/PopupAddEditType";
import _ from "lodash";
export default function useDashboard() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [listSchedule, setListSchedule] = useState([]);
  const [listArtist, setListArtist] = useState([]);
  const [page, setPage] = useState(1);
  const pageSize = PAGE_SIZE;
  const [totalItems, setTotalItems] = useState(0);
  const [filterOption, setFilterOption] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    type: null,
    status: null,
    sort: "ID",
    order: "DESC",
  });
  const [isOpenStreamModal, setIsOpenStreamModal] = useState(false);
  const [streamInfor, setStreamInfor] = useState();
  const { postState } = useSelector((state) => state.PopupAddEditReducer);
  const { listProfile } = useSelector((state) => state.ProfileReducer);
  const userProfile = listProfile;

  useEffect(() => {
    setPage(1);
  }, [filterOption]);

  const fetchListSchedules = async () => {
    setLoading(true);
    let res = await dashboardService.getListSchedule(
      filterOption,
      page,
      pageSize
    );
    if (res && res?.data) {
      setLoading(false);
      const { data } = res?.data;
      setListSchedule(data?.items);
      setTotalItems(data?.totalItems);
    }
  };

  const fetchListArtist = async () => {
    let res = await dashboardService.getListArtist(1, 50);
    if (res && res.data) {
      const { data } = res?.data;
      setListArtist(data);
    }
  };

  const removeAccount = async (delegateId) => {
    let res = await dashboardService.removeAccount(delegateId);
    if (res && res.data.status === "success") {
      fetchListArtist();
      openNotificationWithIcon(
        "success",
        "Success",
        "Remove account successfully!"
      );
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
  };

  const handleView = (row) => {
    // IS POST
    if (row?.type === 1) {
      history.push(
        generatePath("post-management/:postId", { postId: row?.id })
      );
    }
    // IS STREAM
    else if (row?.type === 4) {
      history.push(
        generatePath("stream-management/:streamId", { streamId: row?.id })
      );
    }
  };

  const handleEdit = (row) => {
    const { title, description, media, startTime, id } = row;
    // IS POST
    if (row?.type === 1) {
      dispatch({
        type: SET_ACTION_TYPE,
        payload: { actionType: ActionType.editPost },
      });
      dispatch({
        type: SET_POST_STATE,
        payload: {
          postState: {
            ...postState,
            title,
            description,
            listImgs: media,
            id,
          },
        },
      });
      dispatch({ type: SET_IS_OPEN, payload: { isOpen: true } });
    } // IS STREAM
    else if (row?.type === 4) {
      openStreamModal(row);
    }
  };

  const handleDelete = async (row) => {
    if (row?.type === 1) {
      let res = await postManagementService.deletePost({
        postIds: [row.id],
        status: 2,
      });
      if (res && res?.data?.status === "success") {
        openNotificationWithIcon(
          "success",
          "Success",
          "Delete post successfully!"
        );
        fetchListSchedules();
      }
    } else if (row?.type === 4) {
      let res = await streamService.deleteStream({
        liveStreamIds: [row?.id],
        status: 2,
      });
      if (res && res?.data?.status === "success") {
        openNotificationWithIcon(
          "success",
          "Success",
          "Delete stream successfully!"
        );
        fetchListSchedules();
      }
    }
  };

  const handleMultiDelete = async (selectedItems) => {
    let postIds = [];
    let streamIds = [];
    selectedItems.map((item) => {
      if (item.type === DASHBOARD_ITEMS_VALUE.POST) {
        postIds.push(item.id);
      }
      if (item.type === DASHBOARD_ITEMS_VALUE.STREAM) {
        streamIds.push(item.id);
      }
      return null;
    });
    try {
      if (postIds.length > 0) {
        let res = await postManagementService.deletePost({
          postIds: postIds,
          status: 2,
        });
        if (res && res.data.status === "success") {
          openNotificationWithIcon(
            "success",
            "Success",
            "Delete Posts successfully!"
          );
        }
      }
      if (streamIds.length > 0) {
        let res = await streamService.deleteStream({
          liveStreamIds: streamIds,
          status: 2,
        });
        if (res && res.data.status === "success") {
          openNotificationWithIcon(
            "success",
            "Success",
            "Delete Streams successfully!"
          );
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      fetchListSchedules();
    }
  };

  const closeStreamModal = () => {
    setIsOpenStreamModal(false);
  };

  const openStreamModal = (streamInfor) => {
    setStreamInfor(streamInfor);
    setIsOpenStreamModal(true);
  };

  return {
    listSchedule,
    listArtist,
    page,
    pageSize,
    totalItems,
    filterOption,
    userProfile,
    loading,
    isOpenStreamModal,
    streamInfor,
    setFilterOption,
    fetchListSchedules,
    fetchListArtist,
    handleTableChange,
    removeAccount,
    handleView,
    handleEdit,
    handleDelete,
    handleMultiDelete,
    closeStreamModal
  };
}
